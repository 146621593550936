import {
  useUploadFilesMutation,
  useUploadImagesMutation,
} from "api/Attachment/Attachment";
import Button from "components/Button";
import ContentWrap from "components/ContentWrap";
import DateInput from "components/DateInput";
import Dropdown from "components/Dropdown";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import ImgUpload from "components/ImgUpload";
import Input from "components/Input";
import Textarea from "components/Textarea";
import TitleWrap from "components/TitleWrap";
import { Toast } from "hooks/useToast";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { today } from "utils/onToday";
import { TYPE_LIST, CASTING_LIST } from "utils/type";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import { useMyCastingCallDetail } from "api/Mypage/Mypage";
import { useUpdateCastingCall } from "api/CastingCall/CastingCall";
import { useStaffProfileList } from "api/Member/Member";
import ButtonBackground from "components/ButtonBackground";

const UpdateCastingCall = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const { data: myCastingCallData } = useMyCastingCallDetail(Number(id));

  const myCastingCall = myCastingCallData?.data.data;

  const [castingCallData, setCastingCallData] = useState<{
    castingCallId: null | number;
    title: string;
    description: string;
    deadlineDate: string;
    deadlineType: string;
    location: string;
    format: string;
    role: string;
    minAge: number | null;
    maxAge: number | null;
    ageType: string;
    gender: string;
    stakeholders?: {
      isNew?: boolean;
      agency?: string;
      memberId?: number;
      id?: number;
      name?: string;
      role?: string;
    }[];
    attachments: {
      id: number;
      type: string;
      url: string;
      originalFilename?: string;
      orderNumber: number;
    }[];
    addAttachments: {
      id?: number;
      type?: string;
      orderNumber?: number;
    }[];
    removeAttachmentIds: number[];
    addStakeholders: { memberId?: number; role: string }[];
    removeStakeholderIds: number[];
  }>({
    castingCallId: null,
    title: "",
    description: "",
    deadlineDate: "",
    deadlineType: "",
    location: "",
    format: "",
    role: "",
    minAge: null,
    maxAge: null,
    ageType: "",
    gender: "",
    attachments: [],
    addAttachments: [],
    removeAttachmentIds: [],
    stakeholders: [],
    addStakeholders: [],
    removeStakeholderIds: [],
  });

  useEffect(() => {
    if (myCastingCall) {
      setCastingCallData({
        ...castingCallData,
        castingCallId: Number(id),
        title: myCastingCall.title,
        description: myCastingCall.description,
        deadlineDate: myCastingCall.deadlineDate,
        deadlineType: myCastingCall.deadlineType?.code,
        format: myCastingCall.castingCallFormat?.code!,
        role: myCastingCall.shootingDetails.role.code,
        minAge: myCastingCall.shootingDetails.minAge,
        maxAge: myCastingCall.shootingDetails.maxAge,
        gender: myCastingCall.shootingDetails.gender.code,
        attachments: myCastingCall.attachments.map(
          (attachment: {
            id: number;
            type: {
              code: string;
              label: string;
            };
            originalFilename?: string;
            url: string;
            orderNumber: number;
          }) => ({
            id: attachment.id,
            type: attachment.type.code,
            originalFilename: attachment?.originalFilename,
            orderNumber: attachment.orderNumber,
            url: attachment.url,
          })
        ),
        stakeholders: myCastingCall.stakeholders.map(
          (skateholder: {
            memberId: number;
            agency: string;
            id: number;
            name: string;
            role: { code: string; label: string };
          }) => ({
            memberId: skateholder.memberId,
            agency: skateholder.agency,
            id: skateholder.id,
            role: skateholder.role.code,
            name: skateholder.name,
          })
        ),
      });
    }
  }, [myCastingCall]);

  const { data: staffList } = useStaffProfileList();
  const staffProfileList = staffList?.data.data.content;

  const gender = [
    { id: 0, name: "남자", gender: "MALE" },
    { id: 1, name: "여자 ", gender: "FEMALE" },
    { id: 2, name: "성별무관", gender: "ALL" },
  ];

  const { mutate: uploadImages } = useUploadImagesMutation();
  const { mutate: uploadFile } = useUploadFilesMutation();
  const { mutate: updateCastingCall } = useUpdateCastingCall();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const files = e.target.files;
    if (files) {
      uploadImages(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.data.map(
            (
              item: { attachmentId: number; attachmentUrl: string },
              idx: number
            ) => ({
              isNew: true,
              id: item.attachmentId,
              type: type,
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setCastingCallData({
            ...castingCallData,
            attachments: [...castingCallData.attachments!, ...updateItem],
            addAttachments: [...castingCallData.addAttachments!, ...updateItem],
          });
        },
      });
    }
  };

  const fileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadFile(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.data.map(
            (
              item: { attachmentId: number; attachmentUrl: string },
              idx: number
            ) => ({
              isNew: true,
              id: item.attachmentId,
              type: "FILE",
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setCastingCallData({
            ...castingCallData,
            attachments: [...castingCallData.attachments!, ...updateItem],
            addAttachments: [...castingCallData.addAttachments!, ...updateItem],
          });
        },
      });
    }
  };

  const handleDeleteImage = (id: number, isNew?: boolean) => {
    const updatedAttachments = castingCallData.attachments?.filter(
      (item) => item.id !== id
    );
    const updatedAddAttachments = castingCallData.addAttachments!.filter(
      (item) => item.id !== id
    );
    setCastingCallData({
      ...castingCallData,
      attachments: updatedAttachments,
      addAttachments: updatedAddAttachments,
      removeAttachmentIds: isNew
        ? castingCallData.removeAttachmentIds
        : [...(castingCallData.removeAttachmentIds || []), id],
    });
  };

  const handleDeleteFile = (id: number, isNew?: boolean) => {
    const updatedAttachments = castingCallData.attachments?.filter(
      (item) => item.id !== id
    );
    const updatedAddAttachments = castingCallData.addAttachments!.filter(
      (item) => item.id !== id
    );
    setCastingCallData({
      ...castingCallData,
      attachments: updatedAttachments,
      addAttachments: updatedAddAttachments,
      removeAttachmentIds: isNew
        ? castingCallData.removeAttachmentIds
        : [...(castingCallData.removeAttachmentIds || []), id],
    });
  };

  const removeStakeholder = (memberId: number, id: number, isNew?: boolean) => {
    const updateStakeholder = castingCallData.stakeholders?.filter(
      (item) => item.memberId !== memberId
    );
    const updateAddStakeholder = castingCallData.addStakeholders?.filter(
      (item) => item.memberId !== memberId
    );

    setCastingCallData({
      ...castingCallData,
      stakeholders: updateStakeholder,
      addStakeholders: updateAddStakeholder,
      removeStakeholderIds: isNew
        ? castingCallData.removeStakeholderIds
        : [...(castingCallData.removeStakeholderIds || []), id],
    });
  };

  const decisionMakers = castingCallData?.stakeholders!.filter(
    (stakeholder) => stakeholder.role === "DECISION_MAKER"
  );
  const participants = castingCallData?.stakeholders!.filter(
    (stakeholder) => stakeholder.role === "PARTICIPANT"
  );

  const result = staffProfileList?.filter(
    (a) =>
      !castingCallData?.stakeholders?.some((b) => a.memberId === b.memberId)
  );

  return (
    <div className="pt-[70px] px-5 pb-24">
      <HeaderWithBackButton title={"공고 수정"} />
      <div className="flex flex-col gap-10">
        <TitleWrap require title={"공고 제목"}>
          <Input
            type={"text"}
            value={castingCallData.title}
            onChange={(e) => {
              setCastingCallData({ ...castingCallData, title: e.target.value });
            }}
            placeholder="공고 제목을 입력해 주세요"
          />
        </TitleWrap>
        {!state && (
          <>
            <TitleWrap title={"대표 사진 업로드"}>
              {castingCallData.attachments?.some(
                (i) => i.type === "THUMBNAIL"
              ) ? (
                castingCallData.attachments?.map((item, i) => {
                  if (item.type === "THUMBNAIL")
                    return (
                      <div key={item.id} className="relative">
                        <img
                          className="w-full h-[360px] object-cover"
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                          alt="THUMBNAIL"
                        />
                        <XButton
                          onClick={() => {
                            handleDeleteImage(item.id);
                          }}
                          className="absolute cursor-pointer top-4 right-4"
                        />
                      </div>
                    );
                })
              ) : (
                <ImgUpload
                  accept="image/jpg,impge/png,image/jpeg"
                  onChange={(e) => {
                    imgUpload(e, "THUMBNAIL");
                  }}
                  id={"THUMBNAIL"}
                >
                  <div className="w-full MBody16 bg-Blue01 text-Blue04 px-4 py-3 rounded-[10px] text-center">
                    사진 업로드
                  </div>
                </ImgUpload>
              )}
            </TitleWrap>
            <TitleWrap title={"분야"} require>
              <Dropdown
                width="w-[360px]"
                innerText={
                  castingCallData.format === "MOVIE"
                    ? "영화"
                    : castingCallData.format === "TV_SERIES"
                    ? "드라마"
                    : castingCallData.format === "VARIETY_SHOW"
                    ? "예능"
                    : castingCallData.format === "ADVERTISEMENT"
                    ? "광고"
                    : castingCallData.format === "MUSIC_VIDEO"
                    ? "뮤직비디오"
                    : castingCallData.format === "WEB_SERIES"
                    ? "웹드라마"
                    : castingCallData.format === "SHORT_FORM"
                    ? "숏폼"
                    : castingCallData.format === "INDEPENDENT_FILM"
                    ? "독립영화"
                    : castingCallData.format === "THEATER"
                    ? "연극"
                    : castingCallData.format === "SHORT_FILM"
                    ? "단편영화"
                    : castingCallData.format === "MUSICAL"
                    ? "뮤지컬"
                    : castingCallData.format === "DOCUMENTARY"
                    ? "다큐멘터리"
                    : "선택"
                }
              >
                <div>
                  {TYPE_LIST.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          setCastingCallData({
                            ...castingCallData,
                            format: item.role,
                          });
                        }}
                        key={item.id}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                </div>
              </Dropdown>
            </TitleWrap>
          </>
        )}
        <div className="flex items-center gap-4">
          <TitleWrap title={"배역"}>
            <Dropdown
              width="w-[360px]"
              innerText={
                castingCallData.role === "LEADING_ACTOR"
                  ? "주연"
                  : castingCallData.role === "SUPPORTING_ACTOR"
                  ? "조연"
                  : castingCallData.role === "BIT_PART"
                  ? "단역"
                  : castingCallData.role === "IMAGE_BIT_PART"
                  ? "이미지"
                  : castingCallData.role === "EXTRA"
                  ? "보조출연"
                  : castingCallData.role === "CAMEO"
                  ? "특별출연"
                  : "선택"
              }
            >
              <div>
                {CASTING_LIST.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        setCastingCallData({
                          ...castingCallData,
                          role: item.role,
                        });
                      }}
                      key={item.id}
                    >
                      {item.title}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>
        </div>
        <div className="flex items-center gap-4">
          <TitleWrap title={"성별"} require>
            <Dropdown
              width="w-[172px]"
              innerText={
                castingCallData.gender === "MALE"
                  ? "남자"
                  : castingCallData.gender === "FEMALE"
                  ? "여자"
                  : castingCallData.gender === "ALL"
                  ? "성별무관"
                  : "구분"
              }
            >
              <div>
                {gender.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        setCastingCallData({
                          ...castingCallData,
                          gender: item.gender,
                        });
                      }}
                      key={item.id}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>
          <TitleWrap require title={"나이"}>
            <div className="flex items-center">
              <Input
                inputMode="numeric"
                value={castingCallData.minAge}
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/[^0-9]/g, ""));
                  setCastingCallData({
                    ...castingCallData,
                    minAge: value!,
                  });
                }}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                type={"text"}
              />
              ~
              <Input
                inputMode="numeric"
                value={castingCallData.maxAge}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/[^0-9]/g, ""));
                  setCastingCallData({ ...castingCallData, maxAge: value });
                }}
                type={"text"}
              />
            </div>
          </TitleWrap>
        </div>
        <hr />
        <TitleWrap title={"상세내용"}>
          <Textarea
            value={castingCallData.description}
            onChange={(e) => {
              setCastingCallData({
                ...castingCallData,
                description: e.target.value,
              });
            }}
            className="w-full h-[210px]"
            placeholder="상세내용을 입력해 주세요"
          />
        </TitleWrap>
        <div className="flex items-center gap-4">
          <TitleWrap
            subTitle="PDF파일만 올려주세요(최대 1개)"
            title={"첨부파일"}
          >
            {castingCallData.attachments?.some((i) => i.type === "FILE") ? (
              castingCallData.attachments?.map((item, idx) => {
                if (item.type === "FILE")
                  return (
                    <div key={item.id}>
                      <div className="cursor-pointer inline-flex w-full">
                        <div
                          onClick={() => {
                            window.open(
                              `${process.env.REACT_APP_MEDIA_URL}${item?.url}`,
                              "_blank",
                              "noopener, noreferrer"
                            );
                          }}
                          className="text-Blue04 border border-Blue04 cursor-pointer MBody16 mt-2 py-3 text-center bg-Gray00 rounded-[10px] w-full"
                        >
                          첨부파일 보기
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          handleDeleteFile(item.id);
                        }}
                        className="text-Blue04 border border-Blue04 cursor-pointer MBody16 mt-2 py-3 text-center bg-Gray00 rounded-[10px] w-full"
                      >
                        첨부파일 삭제
                      </div>
                    </div>
                  );
              })
            ) : (
              <ImgUpload
                onChange={(e) => {
                  fileUpload(e);
                }}
                accept=".pdf"
                id={"file"}
              >
                <div className="text-Blue04 MBody16 py-3 text-center bg-Blue01 rounded-[10px] w-[109px]">
                  파일선택
                </div>
              </ImgUpload>
            )}
          </TitleWrap>
        </div>
        <TitleWrap title={"마감일자"}>
          <DateInput
            value={castingCallData.deadlineDate}
            onChange={(e) => {
              setCastingCallData({
                ...castingCallData,
                deadlineDate: e.target.value,
              });
            }}
            min={today()}
            className="w-full"
          />
        </TitleWrap>
        <div className="flex items-start gap-4">
          <TitleWrap title={"후보 확정 권한 추가"}>
            <Dropdown width="w-[172px]" innerText={"선택"}>
              <div>
                {result?.map((item) => {
                  return (
                    <li
                      key={item.memberId}
                      onClick={() => {
                        const skateholder = {
                          memberId: item.memberId,
                          role: "DECISION_MAKER",
                          name: item.name,
                          isNew: true,
                        };
                        setCastingCallData({
                          ...castingCallData,
                          addStakeholders: [
                            ...castingCallData.addStakeholders!,
                            skateholder,
                          ],
                          stakeholders: [
                            ...castingCallData.stakeholders!,
                            skateholder,
                          ],
                        });
                      }}
                      className="flex items-center max-w-[138px] gap-1 overflow-hidden text-ellipsis whitespace-nowrap"
                    >
                      <div className="MBody16 text-Gray09">{item.name}</div>
                      <div className="MBody14 text-Gray05">{item.agency}</div>
                    </li>
                  );
                })}
              </div>
            </Dropdown>
            <div
              className={`flex items-center flex-wrap gap-1 ${
                decisionMakers.length !== 0 && "mt-2"
              }`}
            >
              {decisionMakers?.map((item, i) => {
                return (
                  <div
                    onClick={() => {
                      removeStakeholder(item.memberId!, item.id!, item.isNew);
                    }}
                    key={item.id}
                    className="flex cursor-pointer items-center py-1.5 px-2 bg-Gray02 rounded-[10px]"
                  >
                    <div className="MCaption12">{item.name}</div>
                    <XButton className="w-2.5 h-2.5" />
                  </div>
                );
              })}
            </div>
          </TitleWrap>

          <TitleWrap title={"참조 관계자 추가"}>
            <Dropdown width="w-[172px]" innerText={"선택"}>
              <div>
                {result?.map((item, i) => {
                  return (
                    <li
                      key={item.memberId}
                      onClick={() => {
                        const skateholder = {
                          memberId: item.memberId,
                          name: item.name,
                          role: "PARTICIPANT",
                          isNew: true,
                        };
                        setCastingCallData({
                          ...castingCallData,
                          addStakeholders: [
                            ...castingCallData.addStakeholders!,
                            skateholder,
                          ],
                          stakeholders: [
                            ...castingCallData.stakeholders!,
                            skateholder,
                          ],
                        });
                      }}
                      className="flex items-center max-w-[138px] gap-1 overflow-hidden text-ellipsis whitespace-nowrap"
                    >
                      <div className="MBody16 text-Gray09">{item.name}</div>
                      <div className="MBody14 text-Gray05">{item.agency}</div>
                    </li>
                  );
                })}
              </div>
            </Dropdown>
            <div
              className={`flex items-center flex-wrap gap-1 ${
                participants.length !== 0 && "mt-2"
              }`}
            >
              {participants?.map((item, i) => {
                return (
                  <div
                    onClick={() => {
                      removeStakeholder(item.memberId!, item.id!, item.isNew);
                    }}
                    key={item.memberId}
                    className="flex cursor-pointer items-center py-1.5 px-2 bg-Gray02 rounded-[10px]"
                  >
                    <div className="MCaption12">{item.name}</div>
                    <XButton className="w-2.5 h-2.5" />
                  </div>
                );
              })}
            </div>
          </TitleWrap>
        </div>
      </div>
      <ButtonBackground>
        <Button
          onClick={() => {
            updateCastingCall(castingCallData, {
              onSuccess: () => {
                Toast.success("공고가 수정되었어요");
                navigate("/staff/casting/my", { replace: true });
              },
            });
          }}
          className="w-full h-12 bg-Blue04 text-white border-none"
          text={`수정하기`}
        ></Button>
      </ButtonBackground>
    </div>
  );
};

export default UpdateCastingCall;
