import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Back } from "assets/headerBack.svg";

import { ReactComponent as SMS } from "assets/sms.svg";
import { ReactComponent as PHONE } from "assets/phone.svg";
import { ReactComponent as EMAIL } from "assets/email.svg";
import { getIsMobile } from "utils/IsMobile";
import Modal from "./Modal";

interface HeaderWithBackButtonType {
  title: string;
  onClick?: () => void;
  children?: React.ReactNode;
  phone?: string;
  email?: string;
  sms?: string;
}

const HeaderWithBackButton = ({
  title,
  onClick,
  children,
  phone,
  sms,
  email,
}: HeaderWithBackButtonType) => {
  const [info, setInfo] = useState<{ title: string; desc: string }>({
    title: "",
    desc: "",
  });

  const isMobile = getIsMobile();

  const navigate = useNavigate();
  return (
    <div className="mx-auto px-5 py-2 fixed bg-Gray00 left-2/4 top-0 z-50 -translate-x-2/4 max-w-[400px] w-full BBody20 text-Gray09 ">
      {info.title && (
        <Modal
          onClose={() => {
            setInfo({ title: "", desc: "" });
          }}
          title={info?.title}
        >
          <div className="my-4 MCaption14 text-Gray05">{info.desc}</div>
          <div className="text-right">
            <div
              onClick={() => {
                setInfo({ title: "", desc: "" });
              }}
              className="inline-block cursor-pointer text-Blue04 BBody16"
            >
              확인
            </div>
          </div>
        </Modal>
      )}
      <div className="flex justify-between items-center">
        <Back
          className="cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="absolute left-2/4 -translate-x-2/4 max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
          {title}
        </div>
        <div className="text-center items-center" onClick={onClick}>
          {children}
        </div>
      </div>

      {(phone || email || sms) && (
        <div className="flex MBody14 gap-2  pt-2">
          {phone && (
            <div
              onClick={() => {
                isMobile
                  ? (window.location.href = `tel:${phone}`)
                  : setInfo({ title: "전화번호", desc: phone });
              }}
              className="flex-1 cursor-pointer flex justify-center items-center gap-1 px-4 h-12 border border-Gray03 rounded-[10px]"
            >
              <PHONE />
              <div>전화</div>
            </div>
          )}
          {sms && (
            <div
              onClick={() => {
                isMobile
                  ? (window.location.href = `sms:${phone}`)
                  : setInfo({ title: "문자메세지", desc: phone! });
              }}
              className="flex-1 cursor-pointer flex justify-center items-center gap-1 px-4 h-12 border border-Gray03 rounded-[10px]"
            >
              <SMS />
              <div>문자</div>
            </div>
          )}
          {email && (
            <div
              onClick={() => {
                isMobile
                  ? (window.location.href = `mailto:${email}`)
                  : setInfo({ title: "이메일", desc: email });
              }}
              className="flex-1 cursor-pointer flex justify-center items-center gap-1 px-4 h-12 border border-Gray03 rounded-[10px]"
            >
              <EMAIL />
              <div>메일</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderWithBackButton;
