import { AxiosError, AxiosResponse } from "axios";
import { Toast } from "hooks/useToast";
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from "react-query";
import { useNavigate } from "react-router-dom";

import {
  AuthInfoResponse,
  Login,
  LoginCheckData,
  Signup,
  SignupData,
} from "type/auth";
import { ERROR } from "type/common";
import requestToCastingVote from "utils/requestToCastingVote";

// 회원 가입
const signup = ({
  userId,
  certNum,
  password,
  name,
  phoneNumber,
  email,
  gender,
  birthDate,
  zonecode,
  roadAddress,
  sido,
  jibunAddress,
  sigungu,
  termsOfService,
  termsOfServiceAt,
  privacyPolicy,
  privacyPolicyAt,
  thirdPartySharing,
  thirdPartySharingAt,
  marketingOptIn,
  marketingOptInAt,
  recommendId,
  role,
  businessLicenseId,
  businessName,
  businessNumber,
}: Signup) => {
  return requestToCastingVote({
    method: "POST",
    url: "auth/signup",
    data: {
      userId,
      password,
      name,
      certNum,
      phoneNumber,
      email,
      gender,
      birthDate,
      zonecode,
      roadAddress,
      jibunAddress,
      sido,
      sigungu: sigungu,
      termsOfService: termsOfService,
      termsOfServiceAt: termsOfServiceAt,
      privacyPolicy: privacyPolicy,
      privacyPolicyAt: privacyPolicyAt,
      thirdPartySharing: thirdPartySharing,
      thirdPartySharingAt: thirdPartySharingAt,
      marketingOptIn: marketingOptIn,
      marketingOptInAt: marketingOptInAt,
      recommendId: recommendId,
      role: role,
      businessName: businessName,
      businessNumber: businessNumber,
      businessLicenseId: businessLicenseId,
    },
  });
};
export const useSignupMutation = (
  params: Signup
): UseMutationResult<AxiosResponse<SignupData>, AxiosError<ERROR>, Signup> => {
  const signupMutation = useMutation<
    AxiosResponse<SignupData>,
    AxiosError<ERROR>,
    Signup
  >(["signup"], {
    mutationFn: signup,
    onError: (error) => {
      Toast.error(error.response?.data.message);
    },
  });

  return signupMutation;
};

// 로그인
const login = ({ userId, password, remember }: Login) => {
  return requestToCastingVote({
    method: "POST",
    url: "auth/login",
    data: {
      userId: userId,
      password: password,
      remember: remember,
    },
  });
};
export const useLoginMutation = (
  params: Login
): UseMutationResult<
  AxiosResponse<LoginCheckData>,
  AxiosError<ERROR>,
  Login
> => {
  const loginMutation = useMutation<
    AxiosResponse<LoginCheckData>,
    AxiosError<ERROR>,
    Login
  >({
    mutationFn: login,
    onError: (error) => {
      Toast.error(error.response?.data.message);
    },
  });

  return loginMutation;
};

// 로그인 체크
const getLoginCheck = (islogin: boolean) => {
  return requestToCastingVote({
    method: "GET",
    url: "auth",
  });
};
export const useLoginCheck = (
  isLogin: boolean
): UseQueryResult<AxiosResponse<LoginCheckData>, AxiosError<Error>> =>
  useQuery(["loginCheck", isLogin], () => getLoginCheck(isLogin), {
    enabled: isLogin,
    onError: (error) => {
      Toast.error(error.response?.data.message);
    },
  });

//마이페이지
const fetchAuthInfo = (): Promise<AxiosResponse<AuthInfoResponse>> => {
  return requestToCastingVote.get("/auth/info");
};
export const useAuthInfo = (
  isLogin: boolean
): UseQueryResult<AxiosResponse<AuthInfoResponse>, AxiosError<ERROR>> => {
  return useQuery("authInfo", fetchAuthInfo, {
    enabled: isLogin,
    onError: (err) => {
      Toast.error(err.response?.data.message);
    },
  });
};
