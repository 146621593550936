import React, { useState } from "react";

import { ReactComponent as Back } from "assets/headerBack.svg";
import { ReactComponent as ArrowRight } from "assets/arrowRight.svg";
import { useNavigate } from "react-router-dom";
import { useTodayWithNextMonth } from "hooks/useTodayWithNextMonth";
import Input from "components/Input";

import { FillButton } from "components/FillButton";
import { LineButton } from "components/LineButton";
import { MEMBERSHIP_ACTIVE } from "utils/onRoleStatus";
import BottomPopup from "components/BottomPopup";
import Checkbox from "components/Checkbox";
import ButtonBackground from "components/ButtonBackground";
import { useActiveMembership, useSubscribe } from "api/Payment/Payment";
import { formatDate } from "utils/onDateFormat";

const MembershipSubscribe = () => {
  const navigate = useNavigate();

  const membership = MEMBERSHIP_ACTIVE();
  const [agreeTermModal, setAgreeTermModal] = useState(false);
  const [checkItems, setCheckItems] = useState<number[]>([]);
  const [selectAgree, setSelectAgree] = useState({
    termsOfService: false,
    termsOfServiceAt: "",
    privacyPolicy: false,
    privacyPolicyAt: "",
    thirdPartySharing: false,
    thirdPartySharingAt: "",
    privatePaymentIn: false,
    privatePaymentAt: "",
  });

  const [type, setType] = useState(membership);
  const [cardInfo, setCardInfo] = useState<{
    cardNumber: string;
    cardExpireMM: string;
    cardExpireYY: string;
    name: string;
    cardPasswd: string;
    totalPrice: number;
    membershipTypeId: number;
  }>({
    cardNumber: "",
    cardExpireMM: "",
    cardExpireYY: "",
    name: "",
    cardPasswd: "",
    totalPrice: 33000,
    membershipTypeId: 1,
  });

  const pay = [
    { id: 0, title: "상품 가격", value: "30,000" },
    { id: 1, title: "할인가", value: "0" },
    { id: 2, title: "부가세", value: "3,000", subTitle: "부가세 10%" },
  ];

  const { today, nextMonth } = useTodayWithNextMonth();

  const checkList = [
    {
      id: 0,
      title: "서비스 이용약관 동의",
      type: "termsOfService",
      nav: "/terms",
    },
    {
      id: 1,
      title: "개인정보 수집 및 이용 동의",
      type: "privacyPolicy",
      nav: "/private",
    },
    {
      id: 2,
      title: "제 3자 정보 제공 동의",
      type: "thirdPartySharing",
      nav: "",
    },
    {
      id: 3,
      title: "개인 정보 결제 서비스 업체 제공 동의",
      type: "privatePayment",
      nav: "",
    },
  ];

  const requiredValues = [0, 1, 2, 3];

  const isFormValid = requiredValues.every((val) => checkItems.includes(val));

  const checkItemHandler = (id: number, isChecked: boolean, type: string) => {
    const formattedDate = new Date().toISOString().slice(0, 19);
    if (isChecked) {
      setCheckItems((prev) => [...prev, id]);
      setSelectAgree((pre) => ({
        ...pre,
        [type]: true,
        [`${type}At`]: formattedDate,
      }));
    } else {
      setCheckItems(checkItems.filter((item) => item !== id));
      setSelectAgree((pre) => ({ ...pre, [type]: false, [`${type}At`]: "" }));
    }
  };

  const allCheckedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = new Date().toISOString().slice(0, 19);
    if (e.target.checked) {
      setCheckItems(checkList.map((item) => item.id));
      setSelectAgree((pre) => ({
        ...pre,
        termsOfService: true,
        termsOfServiceAt: formattedDate,
        privacyPolicy: true,
        privacyPolicyAt: formattedDate,
        thirdPartySharing: true,
        thirdPartySharingAt: formattedDate,
        privatePayment: true,
        privatePaymentAt: formattedDate,
      }));
    } else {
      setCheckItems([]);
      setSelectAgree({
        termsOfService: false,
        termsOfServiceAt: "",
        privacyPolicy: false,
        privacyPolicyAt: "",
        thirdPartySharing: false,
        thirdPartySharingAt: "",
        privatePaymentIn: false,
        privatePaymentAt: "",
      });
    }
  };

  const { mutate, isLoading } = useSubscribe();

  const { data } = useActiveMembership();

  return (
    <div className="w-full pb-[103px] min-w-[280px] max-w-[400px] min-h-screen px-5 bg-Gray01 mx-auto">
      {agreeTermModal && (
        <BottomPopup onClose={() => setAgreeTermModal(false)}>
          <div>
            <div className="BHead24 mb-4">
              멤버십 결제 전에
              <br />
              필수 약관 동의가 필요해요.
            </div>
            <div className="flex flex-col gap-4">
              {checkList.map((item) => {
                return (
                  <Checkbox
                    look
                    onClick={() => {
                      navigate(`${item.nav}`);
                    }}
                    id={item.title}
                    checked={checkItems.includes(item.id) ? true : false}
                    onChange={(e) => {
                      checkItemHandler(item.id, e.target.checked, item.type);
                    }}
                    key={item.id}
                    title={item.title}
                  />
                );
              })}
            </div>
            <hr className="w-full h-px my-4 bg-[#D9D9D9]" />
            <Checkbox
              id="전체선택"
              checked={checkItems.length === checkList.length ? true : false}
              onChange={allCheckedHandler}
              className=""
              title="전체 동의하기"
            />
            <ButtonBackground>
              <FillButton
                variant="Membership"
                isLoading={isLoading}
                size="B"
                text="결제하기"
                disabled={!isFormValid}
                onClick={() => {
                  mutate({
                    ...cardInfo,
                    cardNumber: cardInfo.cardNumber.replaceAll("-", ""),
                  });
                }}
              />
            </ButtonBackground>
          </div>
        </BottomPopup>
      )}
      <div className="mx-auto px-5 py-2 fixed bg-Gray01 left-2/4 top-0 z-50 -translate-x-2/4 max-w-[400px] w-full BBody20 text-Gray09 ">
        <div className="flex justify-between items-center">
          <Back
            className="cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="absolute left-2/4 text-Gray09 BBody20 -translate-x-2/4 max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            멤버십
          </div>
        </div>
      </div>
      <div className="w-full pt-[74px]">
        {type ? (
          <div>
            <div className="mb-4 w-full border border-Gray03 bg-Gray00 rounded-[10px] p-5">
              <div className="flex flex-col items-start">
                <div className="MCaption14 text-Gray05">
                  {formatDate(data?.data.startDate!)} -{" "}
                  {formatDate(data?.data.endDate!)}
                </div>
                <div className="flex items-center mt-1.5 mb-1 w-full justify-between">
                  <div className="MBody16 text-Gray09">
                    {data?.data.membershipName}
                  </div>
                  <div className="BBody18 text-Blue04">
                    {data?.data.price.toLocaleString()}원
                  </div>
                </div>
                <div className="RCaption12 text-Gray05">
                  다음 결제 예정일: {formatDate(data?.data.endDate!)}
                </div>
              </div>
              <hr className="my-4 h-px w-full text-Gray03" />
              <div className="flex items-center cursor-pointer w-full justify-between">
                <div className="MCaption14 text-Gray09">결제내역</div>
                <ArrowRight />
              </div>
            </div>
            <div className="mb-4 w-full border border-Gray03 bg-Gray00 rounded-[10px] p-5">
              <div
                onClick={() => {
                  navigate("/membership/payment-method");
                }}
                className="flex items-center cursor-pointer w-full justify-between"
              >
                <div className="MCaption14 text-Gray09">결제 수단 변경</div>
                <ArrowRight />
              </div>
            </div>
            <div className="text-Gray05">
              <div className="MCaption14">이용안내</div>
              <ol className="list-disc list-inside RCaption14">
                <li>결제 금액에는 부가세가 포함되어 있어요.</li>
                <li>
                  언제든지 자동 결제를 해지할 수 있어요. 해지하면 다음 결제
                  예정일부터 결제가 진행되지 않아요.
                </li>
                <li>
                  미성년자가 결제하려면 법정대리인의 동의가 필요해요. 동의를
                  받지 않은 경우 법정대리인이 구매를 취소할 수 있어요.
                </li>
              </ol>
            </div>
          </div>
        ) : (
          <div>
            <div className="BBody20 text-Gray09">결제</div>
            <div className="RBody14 text-Gray05 mt-1">
              이용기간 {today} - {nextMonth}
            </div>
            <div className="my-4 w-full border border-Gray03 bg-Gray00 rounded-[10px] p-5">
              <div className="flex flex-col items-start gap-2">
                {pay.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="flex items-start w-full MBody16 text-Gray05 justify-between"
                    >
                      <div>{item.title}</div>
                      <div className="text-right">
                        <div>{item.value}원</div>
                        <div className="RBody16">{item.subTitle}</div>
                      </div>
                    </div>
                  );
                })}
                <hr className="my-4 h-px w-full text-Gray03" />
                <div className="flex items-start w-full MBody16 text-Gray05 justify-between">
                  <div>총 결제금액</div>
                  <div className="BBody18 text-Blue04">33,000원</div>
                </div>
              </div>
            </div>
            <hr className="my-10 h-px w-full  text-Gray03" />
            <div className="flex flex-col BBody16 mb-4 w-full gap-4">
              <div>
                <div>카드번호</div>
                <Input
                  inputMode="numeric"
                  className="mt-2"
                  value={cardInfo?.cardNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    const numericValue = value.replace(/\D/g, "");

                    if (numericValue.length > 16) return;

                    let formattedValue = numericValue;
                    if (numericValue.length > 4) {
                      formattedValue =
                        numericValue.slice(0, 4) + "-" + numericValue.slice(4);
                    }
                    if (numericValue.length > 8) {
                      formattedValue =
                        formattedValue.slice(0, 9) +
                        "-" +
                        formattedValue.slice(9);
                    }
                    if (numericValue.length > 12) {
                      formattedValue =
                        formattedValue.slice(0, 14) +
                        "-" +
                        formattedValue.slice(14);
                    }

                    setCardInfo({
                      ...cardInfo,
                      cardNumber: formattedValue,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (["e", "E", "+", "."].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="0000 0000 0000 0000"
                  type={"text"}
                ></Input>
              </div>
              <div>
                <div>유효기간</div>
                <div className="flex gap-2 mt-2 items-center">
                  <Input
                    inputMode="numeric"
                    value={cardInfo?.cardExpireMM}
                    onChange={(e) => {
                      const value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 2);
                      if (
                        value.length === 2 &&
                        (Number(value) < 1 || Number(value) > 12)
                      ) {
                        return;
                      }
                      setCardInfo({
                        ...cardInfo,
                        cardExpireMM: value,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (["e", "E", "+", "-", "."].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    placeholder="MM"
                    type={"text"}
                  />
                  <Input
                    inputMode="numeric"
                    value={cardInfo?.cardExpireYY}
                    onChange={(e) => {
                      // const currentYear = new Date().getFullYear() % 100; // 현재 연도의 두 자리 표현 (예: 2024 -> 24)
                      const value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 2);
                      //   .replace(/[^0-9]/g, "")
                      //   .slice(0, 2);
                      // if (value.length === 2 && Number(value) < currentYear) {
                      //   return; // 만료된 연도는 무시
                      // }
                      setCardInfo({
                        ...cardInfo,
                        cardExpireYY: value,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (["e", "E", "+", "-", "."].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    placeholder="YY"
                    type={"number"}
                  />
                </div>
              </div>
              <div>
                <div>이름</div>
                <Input
                  value={cardInfo?.name}
                  onChange={(e) => {
                    const value = e.target.value;
                    setCardInfo({
                      ...cardInfo,
                      name: value,
                    });
                  }}
                  className="mt-2"
                  placeholder="이름"
                  type={"text"}
                />
              </div>
              <div>
                <div>카드 비밀번호</div>
                <div className="flex gap-2 mt-2 items-center">
                  <Input
                    inputMode="numeric"
                    value={cardInfo?.cardPasswd}
                    onKeyDown={(e) => {
                      if (["e", "E", "+", "-", "."].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setCardInfo({
                        ...cardInfo,
                        cardPasswd: e.target.value
                          .replace(/[^0-9]/g, "")
                          .slice(0, 2),
                      });
                    }}
                    className="flex-1"
                    placeholder="00"
                    type={"password"}
                  />
                  <div className="flex min-w-44 items-center gap-2">
                    <div className="w-2 h-2 rounded-full bg-Gray09"></div>
                    <div className="w-2 h-2 rounded-full bg-Gray09"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-start flex-col gap-4 text-Gray05">
              <div>
                <div className="MCaption14">이용안내</div>
                <ol className="list-disc list-inside RCaption14">
                  <li>결제 금액에는 부가세가 포함되어 있어요.</li>
                  <li>
                    언제든지 자동 결제를 해지할 수 있어요. 해지하면 다음 결제
                    예정일부터 결제가 진행되지 않아요.
                  </li>
                  <li>
                    미성년자가 결제하려면 법정대리인의 동의가 필요해요. 동의를
                    받지 않은 경우 법정대리인이 구매를 취소할 수 있어요.
                  </li>
                </ol>
              </div>
              <div>
                <div className="MCaption14">필수 약관 동의</div>
                <ol className="list-disc list-inside RCaption14">
                  <li>이용약관 및 개인정보 처리방침 동의</li>
                  <li>개인정보 제3자 제공 동의</li>
                  <li>개인 정보 결제 서비스 업체 제공 동의</li>
                </ol>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="max-w-[400px] bg-Gray01 w-full fixed bottom-0 left-2/4 -translate-x-2/4 pb-[35px] px-5">
        {type ? (
          <LineButton
            onClick={() => {
              navigate("/membership/cancel");
            }}
            className="w-full"
            text="멤버십 해지"
            variant="normal"
            size="L"
          ></LineButton>
        ) : (
          <FillButton
            onClick={() => {
              setAgreeTermModal(true);
            }}
            variant="Membership"
            text="약관 동의하기"
            size={"B"}
          ></FillButton>
        )}
      </div>
    </div>
  );
};

export default MembershipSubscribe;
