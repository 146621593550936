import { AxiosError, AxiosResponse } from "axios";
import { Toast } from "hooks/useToast";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { ERROR } from "type/common";
import {
  ActiveMembershipResponse,
  MembershipHistoryParams,
  paymentHistoryParams,
  subscribeParams,
  UpdateCardParams,
  WelcomePaySubscribeResponse,
} from "type/payment";
import requestToCastingVote from "utils/requestToCastingVote";

// 결제 내역 조회
const fectchPaymentHistory = (params: paymentHistoryParams) => {
  return requestToCastingVote({ method: "GET", url: "/payments", params }).then(
    (res) => res.data
  );
};
export const usePaymentHistory = (params: paymentHistoryParams) => {
  return useInfiniteQuery(
    ["paymentHistory", params],
    ({ pageParam = 0 }) =>
      fectchPaymentHistory({ ...params, page: pageParam, size: 20 }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 웰컴 페이 멤버십 구독
const fectchSubscribe = (
  data: subscribeParams
): Promise<AxiosResponse<WelcomePaySubscribeResponse>> => {
  return requestToCastingVote({
    method: "POST",
    url: "payments/welcomepay/subscribe",
    data: data,
  }).then((res) => res.data);
};
export const useSubscribe = () => {
  return useMutation<
    AxiosResponse<WelcomePaySubscribeResponse>,
    AxiosError<ERROR>,
    subscribeParams
  >(fectchSubscribe, {
    onError: (error) => {
      Toast.error(error.response?.data.message);
    },
  });
};

// 웰컴 페이 멤버십 구독 카드 수정
const fetchCardUpdate = (
  data: UpdateCardParams
): Promise<AxiosResponse<WelcomePaySubscribeResponse>> => {
  return requestToCastingVote({
    method: "PATCH",
    url: "/payments/welcomepay/billing",
    data,
  }).then((res) => res.data);
};
export const useCardUpdate = () => {
  return useMutation<
    AxiosResponse<WelcomePaySubscribeResponse>,
    AxiosError<ERROR>,
    UpdateCardParams
  >(fetchCardUpdate, {
    onSuccess: () => {
      Toast.success("공고 상태가 성공적으로 변경되었습니다.");
    },
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};

// 웰컴 페이 멤버십 구독 해지
const fetchCancelSubscription = (): Promise<
  AxiosResponse<WelcomePaySubscribeResponse>
> => {
  return requestToCastingVote({
    method: "DELETE",
    url: "/payments/welcomepay/billing",
  }).then((res) => res.data);
};

export const useCancelSubscription = () => {
  return useMutation<
    AxiosResponse<WelcomePaySubscribeResponse>,
    AxiosError<ERROR>
  >(fetchCancelSubscription, {
    onError: (error: AxiosError<ERROR>) => {
      Toast.error(error.response?.data.message);
    },
  });
};
// 멤버십 결제 이력
const fetchMembershipHistory = (params: MembershipHistoryParams) => {
  return requestToCastingVote({
    method: "GET",
    url: "/membership/histories",
    params,
  }).then((res) => res.data);
};
export const useMembershipHistory = (params: MembershipHistoryParams) => {
  return useInfiniteQuery(
    ["membershipHistory", params],
    ({ pageParam = 0 }) =>
      fetchMembershipHistory({ ...params, page: pageParam, size: 20 }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};

// 사용중인 멤버십
const fetchActiveMembership = () => {
  return requestToCastingVote({ method: "GET", url: "/membership" }).then(
    (res) => res.data
  );
};
export const useActiveMembership = () => {
  return useQuery<ActiveMembershipResponse, AxiosError<ERROR>>(
    "activeMembership",
    fetchActiveMembership,
    {
      onError: (error: AxiosError<ERROR>) => {
        Toast.error(error.response?.data.message);
      },
    }
  );
};
