import React, { useEffect, useState } from "react";
import { Route, Router, Routes, useLocation } from "react-router-dom";
import Main from "./pages/Main";
import Layout from "./components/Layout";
import Login from "pages/Login";
import Signup from "pages/Signup";

import ArtistList from "pages/ArtistList";
import ArtistDetail from "pages/ArtistDetail";
import StaffList from "pages/StaffList";
import StaffDetail from "pages/StaffDetail";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "react-datepicker/dist/react-datepicker.css";

import CastingList from "pages/CastingList";
import CastingDetail from "pages/CastingDetail";
import StaffFilmoDetail from "pages/StaffFilmoDetail";
import CreateArtistProfile from "pages/CreateArtistProfile";
import CreateStaffProfile from "pages/CreateStaffProfile";

import { authInfo, info, loginCheck } from "store/auth/atom";
import { useRecoilState } from "recoil";
import { innderHeightValue } from "store/auth/atom";
import StaffMy from "pages/StaffMy";
import ArtistMy from "pages/ArtistMy";
import More from "pages/More";
import CreateCastingCall from "pages/CreateCastingCall";
import CreateProduction from "pages/CreateProduction";
import StaffMyCasting from "pages/StaffMyCasting";
import PointsDetails from "pages/PointsDetails";
import CacheCharge from "pages/CacheCharge";
import ReceivedProfile from "pages/ReceivedProfile";
import { useAuthInfo, useLoginCheck } from "api/auth";
import SuggestProduct from "pages/SuggestProduct";
import ArtistReceiveSuggest from "pages/ArtistReceiveSuggest";
import UpdateArtistProfile from "pages/UpdateArtistProfile";
import ArtistSendProfile from "pages/ArtistSendProfile";
import StaffMyFilmo from "pages/StaffMyFilmo";
import MyCasting from "pages/MyCasting";
import CastingProcess from "pages/CastingProcess";
import ArtistFeedback from "pages/ArtistFeedback";
import KmcCallback from "pages/KmcCallback";
import UpdateStaffProfile from "pages/UpdateStaffProfile";
import UpdateCastingCall from "pages/UpdateCastingCall";
import UpdateProduction from "pages/UpdateProduction";
import Terms from "pages/Terms";
import Private from "pages/Private";
import CastingReference from "pages/CastingReference";
import ArtistShared from "pages/ArtistShared";
import Membership from "pages/Membership";
import MyPage from "pages/MyPage";
import MembershipSubscribe from "pages/MembershipSubscribe";
import MembershipCancel from "pages/MembershipCancel";
import MembershipPaymentMethod from "pages/MembershipPaymentMethod";
import ArtistPick from "pages/ArtistPick";
import NotFound from "pages/NotFound";
import requestToCastingVote from "utils/requestToCastingVote";

function App() {
  const location = useLocation();
  const [innderHeight, setInnderHeight] =
    useRecoilState<number>(innderHeightValue);
  const [login, setLogin] = useRecoilState(loginCheck);
  const updatedUserInfo = localStorage.getItem("user");
  let userInfo = updatedUserInfo ? JSON.parse(updatedUserInfo) : null;
  const [auth, setAuth] = useRecoilState(authInfo);
  const [userData, setUserData] = useRecoilState<{
    id: string;
    username: string;
    role: string;
  }>(info);
  const isLogin = userInfo?.id && location.search !== "?code=4001";

  const { data } = useLoginCheck(!!isLogin);
  const { data: authData } = useAuthInfo(!!isLogin);

  useEffect(() => {
    if (data) {
      const info = data.data.data;
      const id = String(info?.memberId);
      const userInfo = {
        id: id,
        username: info.username,
        role: info.role,
      };
      const userJson = JSON.stringify(userInfo);
      setUserData(userInfo);
      localStorage.clear();
      localStorage.setItem("user", userJson);
      setLogin(true);
    }
  }, [data]);

  useEffect(() => {
    if (authData) {
      setAuth(authData?.data.data!);
      setLogin(true);
    }
  }, [authData]);
  // 02-517-2468

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  // useEffect(() => {
  //   requestToCastingVote({
  //     method:"POST",
  //     url:"cert/request",
  //     data: {
  //       phoneNumber: '010-6646-0960'
  //     }
  //   })
  // },[])

  window.addEventListener("resize", () => setScreenSize());

  return (
    <Routes>
      <Route path="/*" element={<NotFound />} />
      <Route path="/membership" element={<Membership />} />
      <Route path="/membership/subscribe" element={<MembershipSubscribe />} />
      <Route path="/membership/cancel" element={<MembershipCancel />} />
      <Route
        path="/membership/payment-method"
        element={<MembershipPaymentMethod />}
      />
      <Route element={<Layout />}>
        <Route path="/terms" element={<Terms />} />
        <Route path="/private" element={<Private />} />
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/kmc/callback" element={<KmcCallback />} />

        <Route path="/more" element={<More />} />
        <Route path="/mypage" element={<MyPage />} />

        <Route path="/artist" element={<ArtistList />} />
        <Route path="/artist/pick" element={<ArtistPick />} />
        <Route path="/artist/:artistId" element={<ArtistDetail />} />
        <Route path="/artist/profile" element={<CreateArtistProfile />} />
        <Route path="/artist/profile/edit" element={<UpdateArtistProfile />} />
        <Route path="/artist/my" element={<ArtistMy />} />
        <Route path="/artist/suggest" element={<ArtistReceiveSuggest />} />
        <Route path="/artist/send" element={<ArtistSendProfile />} />
        <Route path="/artist/share" element={<ArtistShared />} />

        <Route path="/suggest" element={<SuggestProduct />} />

        <Route path="/point" element={<PointsDetails />} />
        <Route path="/point/charge" element={<CacheCharge />} />

        <Route path="/staff" element={<StaffList />} />
        <Route path="/staff/feedback/:artistId" element={<ArtistFeedback />} />
        <Route path="/staff/:staffId" element={<StaffDetail />} />
        <Route path="/staff/profile" element={<CreateStaffProfile />} />
        <Route path="/staff/my" element={<StaffMy />} />
        <Route path="/staff/reference" element={<CastingReference />} />
        <Route path="/staff/profile/edit" element={<UpdateStaffProfile />} />
        <Route path="/staff/casting/my" element={<StaffMyCasting />} />
        <Route path="/staff/casting/process/:id" element={<CastingProcess />} />
        <Route path="/staff/post" element={<CreateProduction />} />
        <Route path="/staff/post/edit/:id" element={<UpdateProduction />} />
        <Route path="/staff/casting-call" element={<CreateCastingCall />} />
        <Route
          path="/staff/casting-call/edit/:id"
          element={<UpdateCastingCall />}
        />
        <Route path="/staff/filmo/:staffId" element={<StaffFilmoDetail />} />
        <Route path="/staff/filmo/my/:productId" element={<StaffMyFilmo />} />
        <Route path="/staff/received" element={<ReceivedProfile />} />

        <Route path="/casting" element={<CastingList />} />
        <Route path="/casting/:itemId" element={<CastingDetail />} />
        <Route path="/casting/my/:itemId" element={<MyCasting />} />
      </Route>
    </Routes>
  );
}

export default App;
