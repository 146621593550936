import React, { useEffect, useState } from "react";

import { useProductionDetailQuery } from "api/Mypage/Mypage";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import ContentWrap from "components/ContentWrap";
import Dropdown from "components/Dropdown";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import ImgUpload from "components/ImgUpload";
import Input from "components/Input";
import Textarea from "components/Textarea";
import TitleWrap from "components/TitleWrap";
import { Toast } from "hooks/useToast";
import { TYPE_LIST } from "utils/type";
import { ProductionData } from "type/staff";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import { useUploadImagesMutation } from "api/Attachment/Attachment";
import { useUpdateProduction } from "api/Production/Production";
import ButtonBackground from "components/ButtonBackground";

const UpdateProduction = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isNew, setIsNew] = useState(false);
  const [postData, setPostData] = useState<ProductionData>({
    productionId: null,
    title: "",
    format: "",
    description: "",
    directorName: "",
    companyName: "",
    attachmentId: null,
    addAttachmentId: null,
    removeAttachmentId: null,
  });

  const { data } = useProductionDetailQuery(Number(id));
  const prodDetail = data?.data.data;

  useEffect(() => {
    if (prodDetail) {
      setPostData({
        ...postData,
        productionId: prodDetail.productionId,
        title: prodDetail.title,
        format: prodDetail.format.code,
        description: prodDetail.description,
        attachmentUrl: prodDetail.thumbnailUrl,
        companyName: prodDetail.companyName,
        directorName: prodDetail.directorName,
        attachmentId: prodDetail.thumbnailId,
      });
    }
  }, [prodDetail]);

  const { mutate: uploadImages } = useUploadImagesMutation();

  const { mutate: updateProduction } = useUpdateProduction();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadImages(files, {
        onSuccess: (res) => {
          setPostData({
            ...postData,
            addAttachmentId: res?.data.data[0].attachmentId,
            attachmentUrl: res?.data.data[0].attachmentUrl,
          });
          setIsNew(true);
        },
      });
    }
  };

  const valid = (postData: ProductionData) => {
    if (!postData.title) return "작품제목은 필수입니다.";
    if (!postData.format) return "분야를 선택해주세요.";
    if (!postData.description) return "작품설명을 입력해주세요.";
    return null;
  };

  return (
    <div className="pb-24 px-5">
      <HeaderWithBackButton title={`작품수정`} />
      <div className="pt-20 flex flex-col gap-10">
        <TitleWrap title={"대표사진 업로드"}>
          {postData.attachmentUrl ? (
            <div className="relative">
              <img
                className="w-full h-[450px] rounded-[10px] object-cover"
                src={`${process.env.REACT_APP_MEDIA_URL}${postData?.attachmentUrl}`}
                alt="ProfileImg"
              />
              <XButton
                onClick={() => {
                  if (isNew) {
                    setPostData({
                      ...postData,
                      addAttachmentId: null,
                      attachmentUrl: "",
                    });
                  } else {
                    setPostData({
                      ...postData,
                      removeAttachmentId: postData.attachmentId,
                      attachmentUrl: "",
                      attachmentId: null,
                    });
                  }
                }}
                className="absolute cursor-pointer top-4 right-4"
              />
            </div>
          ) : (
            <ImgUpload
              accept="image/jpg,impge/png,image/jpeg"
              onChange={(e) => {
                imgUpload(e);
              }}
              id={"Prod_profile"}
            >
              <div className="w-full MBody16 bg-Blue01 object-cover text-Blue04 px-4 py-3 rounded-[10px] text-center">
                대표 사진 업로드
              </div>
            </ImgUpload>
          )}
        </TitleWrap>
        <TitleWrap require title={"작품제목"}>
          <Input
            value={postData?.title}
            onChange={(e) => {
              setPostData({ ...postData, title: e.target.value });
            }}
            type={"text"}
            placeholder="작품제목을 입력해 주세요"
          />
        </TitleWrap>
        <TitleWrap require title={"분야"}>
          <Dropdown
            innerText={
              postData.format === "MOVIE"
                ? "영화"
                : postData.format === "TV_SERIES"
                ? "드라마"
                : postData.format === "VARIETY_SHOW"
                ? "예능"
                : postData.format === "MUSIC_VIDEO"
                ? "뮤직비디오"
                : postData.format === "WEB_SERIES"
                ? "웹드라마"
                : postData.format === "SHORT_FORM"
                ? "숏폼"
                : postData.format === "INDEPENDENT_FILM"
                ? "독립영화"
                : postData.format === "SHORT_FILM"
                ? "단편영화"
                : postData.format === "THEATER"
                ? "연극"
                : postData.format === "MUSICAL"
                ? "뮤지컬"
                : postData.format === "DOCUMENTARY"
                ? "다큐멘터리"
                : "선택"
            }
            width="w-full"
          >
            <div>
              {TYPE_LIST.map((item) => {
                return (
                  <li
                    onClick={() => {
                      setPostData({ ...postData, format: item.role });
                    }}
                    key={item.id}
                  >
                    {item.title}
                  </li>
                );
              })}
            </div>
          </Dropdown>
        </TitleWrap>
        <div className="flex items-center gap-4">
          <TitleWrap title={"감독"}>
            <Input
              value={postData?.directorName}
              onChange={(e) => {
                setPostData({ ...postData, directorName: e.target.value });
              }}
              type={"text"}
              placeholder="감독을 입력해 주세요"
            />
          </TitleWrap>
          <TitleWrap title={"제작사"}>
            <Input
              value={postData?.companyName}
              onChange={(e) => {
                setPostData({ ...postData, companyName: e.target.value });
              }}
              type={"text"}
              placeholder="제작사를 입력해 주세요"
            />
          </TitleWrap>
        </div>
        <TitleWrap require title={"작품설명"}>
          <Textarea
            value={postData?.description}
            onChange={(e) => {
              setPostData({ ...postData, description: e.target.value });
            }}
            className="w-full h-[210px]"
            placeholder="작품설명을 입력해 주세요"
          />
        </TitleWrap>
      </div>
      <ButtonBackground>
        <Button
          onClick={() => {
            const validError = valid(postData);
            if (validError !== null) {
              Toast.error(validError);
            } else {
              updateProduction(postData, {
                onSuccess: () => {
                  Toast.success("작품이 수정되었어요");
                  navigate("/staff/casting/my", { replace: true });
                },
              });
            }
          }}
          className="w-full h-12 bg-Blue04 text-white border-none"
          text={"수정하기"}
        ></Button>
      </ButtonBackground>
    </div>
  );
};

export default UpdateProduction;
