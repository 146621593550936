import React, { useEffect, useState } from "react";

import ContentWrap from "components/ContentWrap";
import TitleWrap from "components/TitleWrap";
import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import Input from "components/Input";
import ImgUpload from "components/ImgUpload";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import Button from "components/Button";
import { useMyPage } from "api/Mypage/Mypage";
import { useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useUpdateStaffProfileMutation } from "api/Profile/Profile";
import { Toast } from "hooks/useToast";
import { useUploadImagesMutation } from "api/Attachment/Attachment";
import { StaffProfileUpdateRequest } from "type/artist";
import ButtonBackground from "components/ButtonBackground";

const UpdateStaffProfile = () => {
  const navigate = useNavigate();
  const [staffData, setStaffData] = useState<StaffProfileUpdateRequest>({
    profileId: null,
    filmographies: [],
    addFilmographies: [],
    removeFilmographyIds: [],
    attachments: [],
    addAttachments: [],
    removeAttachments: [],
  });

  const { data, isLoading, isError, refetch } = useMyPage();

  const Staff = data?.data.data;

  useEffect(() => {
    if (data) {
      setStaffData({
        ...staffData,
        profileId: Staff?.profileId!,
        filmographies:
          Staff?.filmographies?.map(
            (film: {
              id: number;
              title: string;
              releaseYear: string;
              orderNumber: number;
            }) => ({
              id: film.id,
              title: film.title,
              releaseYear: Number(film.releaseYear),
              orderNumber: film.orderNumber,
            })
          ) || [],
        attachments:
          Staff?.attachments.map(
            (attachment: {
              id: number;
              type: {
                code: string;
                label: string;
              };
              url: string;
              orderNumber: number;
            }) => ({
              id: attachment.id,
              type: attachment.type.code,
              orderNumber: attachment.orderNumber,
              url: attachment.url,
            })
          ) || [],
      });
    }
  }, [data]);

  const { mutate: uploadImages } = useUploadImagesMutation();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadImages(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.data.map(
            (
              item: { attachmentId: number; attachmentUrl: string },
              idx: number
            ) => ({
              isNew: true,
              id: item.attachmentId,
              type: "THUMBNAIL",
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setStaffData({
            ...staffData,
            attachments: [...staffData.attachments!, ...updateItem],
            addAttachments: [...staffData.addAttachments!, ...updateItem],
          });
        },
      });
    }
  };

  const handleDeleteImage = (id: number, isNew?: boolean) => {
    const updatedAttachments = staffData.attachments!.filter(
      (item) => item.id !== id
    );
    const updatedAddAttachments = staffData.addAttachments!.filter(
      (item) => item.id !== id
    );
    setStaffData({
      ...staffData,
      attachments: updatedAttachments,
      addAttachments: updatedAddAttachments,
      removeAttachments: isNew
        ? staffData.removeAttachments
        : [...(staffData.removeAttachments || []), id],
    });
  };

  const handleAddFilmography = () => {
    setStaffData((prevState) => ({
      ...prevState,
      addFilmographies: [
        {
          isNew: true,
          title: "",
          releaseYear: 0,
          orderNumber: prevState.filmographies!.length,
        },
        ...prevState.addFilmographies!,
      ],
    }));
  };

  const handleDeleteFilmography = (index: number) => {
    setStaffData((prevState) => ({
      ...prevState,
      addFilmographies: prevState.addFilmographies!.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleDeletePreFilmography = (id: number) => {
    const updatedAddAttachments = staffData.filmographies!.filter(
      (item) => item.id !== id
    );
    setStaffData({
      ...staffData,
      filmographies: updatedAddAttachments,
      removeFilmographyIds: [...staffData.removeFilmographyIds!, id],
    });
  };

  const handleInputChange = (
    index: number,
    field: "title" | "releaseYear",
    value: string | number
  ) => {
    setStaffData((prevState) => ({
      ...prevState,
      addFilmographies: prevState.addFilmographies!.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      ),
    }));
  };

  const { mutate, isSuccess } = useUpdateStaffProfileMutation(staffData);

  const valid = (data: StaffProfileUpdateRequest) => {
    const hasThumbnail = data?.attachments!.some(
      (attachment) => attachment.type === "THUMBNAIL"
    );
    if (!hasThumbnail) return "프로필 이미지는 필수입니다.";
    if (
      staffData?.addFilmographies!?.some((filmography) => {
        return !filmography.title || !filmography.releaseYear;
      })
    )
      return "필모그래피는 빈칸없이 입력해주세요";
    return null;
  };

  return (
    <div className="pt-[70px] pb-24 px-5">
      <HeaderWithBackButton title="내 프로필" />
      <div className="flex items-start gap-5 flex-col">
        <div className="w-full">
          <TitleWrap
            subTitle="4:5 / 10MB 이하"
            title={"프로필 사진 업로드"}
            require
          >
            {staffData?.attachments!.length ? (
              <div className="relative w-[172px]">
                <img
                  className="w-[172px] h-[215px] rounded-[10px] object-cover"
                  src={`${process.env.REACT_APP_MEDIA_URL}${staffData?.attachments[0].url}`}
                  alt="THUMBNAIL"
                />
                <XButton
                  onClick={() => {
                    handleDeleteImage(
                      staffData?.attachments![0].id!,
                      staffData?.attachments![0].isNew
                    );
                  }}
                  className="absolute cursor-pointer top-4 right-4"
                />
              </div>
            ) : (
              <ImgUpload
                onChange={(e) => {
                  imgUpload(e);
                }}
                accept="image/jpg,impge/png,image/jpeg"
                id={"profileImg"}
              >
                <div className="w-[172px] cursor-pointer h-[216px] MBody16 flex flex-col justify-center text-center text-Blue04 border border-[#D9D9D9] border-dashed">
                  <div>사진 업로드</div>
                  <IconPlus className="mx-auto mt-4" />
                </div>
              </ImgUpload>
            )}
            <div className="mt-4 rounded-[10px] bg-Gray01 p-4">
              <div className="RBody14 text-Gray05">
                <div>이런 사진은 프로필 등록이 불가능해요.</div>
                <ul>
                  <li>• 워터마크가 포함된 사진</li>
                  <li>• 과도하게 편집된 사진</li>
                  <li>• 화질이 너무 안 좋은 사진</li>
                  <li>• 다른 사람이 포함된 사진</li>
                  <li>• 담배나 술이 확인되는 사진</li>
                  <li>• 사회통념에 반하는 내용이 들어간 사진</li>
                  <li>
                    • 타인이나 특정 계층을 모욕하거나 비하•비방하는 내용의 사진
                  </li>
                </ul>
              </div>
            </div>
          </TitleWrap>
        </div>
        <div className="w-full">
          <TitleWrap title={"필모그래피"}>
            <IconPlus
              className="absolute BBody16 text-Blue04 cursor-pointer -top-11 right-0"
              onClick={() => handleAddFilmography()}
            />
            <div className="flex flex-col gap-2">
              {staffData.addFilmographies!.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="w-full rounded-[10px] relative gap-4 bg-Gray01 p-4 flex"
                  >
                    <TitleWrap title={"제작년도"} require>
                      <Input
                        type={"number"}
                        inputMode="numeric"
                        className="w-[93px]"
                        placeholder="제작년도를 입력해 주세요"
                        value={item.releaseYear}
                        onKeyDown={(e) => {
                          if (["e", "E", "+", "-", "."].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          handleInputChange(
                            i,
                            "releaseYear",
                            e.target.value.replace(/[^0-9]/g, "").slice(0, 4)
                          )
                        }
                      />
                    </TitleWrap>
                    <TitleWrap subTitle="최대36자" title={"작품제목"} require>
                      <Input
                        type={"text"}
                        className="w-full"
                        placeholder="작품제목을 입력해 주세요"
                        value={item.title}
                        onChange={(e) =>
                          handleInputChange(i, "title", e.target.value)
                        }
                      />
                    </TitleWrap>
                    <div
                      onClick={() => handleDeleteFilmography(i)}
                      className="absolute right-5 BBody16 text-Blue04 cursor-pointer"
                    >
                      삭제
                    </div>
                  </div>
                );
              })}
              {staffData.filmographies!.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="w-full rounded-[10px] relative gap-4 bg-Gray01 p-4 flex"
                  >
                    <TitleWrap title={"제작연도"} require>
                      <Input
                        type={"number"}
                        inputMode="numeric"
                        value={item.releaseYear}
                        disabled
                      />
                    </TitleWrap>
                    <TitleWrap subTitle="최대36자" title={"작품제목"} require>
                      <Input type={"text"} value={item.title} disabled />
                    </TitleWrap>
                    <div
                      onClick={() => handleDeletePreFilmography(item?.id!)}
                      className="absolute right-5 BBody16 text-Blue04 cursor-pointer"
                    >
                      삭제
                    </div>
                  </div>
                );
              })}
            </div>
          </TitleWrap>
        </div>
      </div>
      <ButtonBackground>
        <Button
          onClick={() => {
            const validError = valid(staffData);
            if (validError !== null) {
              Toast.error(validError);
            } else {
              mutate(staffData, {
                onSuccess: () => {
                  Toast.success("프로필을 수정했어요");
                  navigate("/staff/my", { replace: true });
                },
              });
            }
          }}
          className="w-full h-12 bg-Blue04 text-white border-none"
          text={"수정하기"}
        ></Button>
      </ButtonBackground>
    </div>
  );
};

export default UpdateStaffProfile;
