import React, { useCallback, useEffect, useState } from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import { ReactComponent as Share } from "../assets/share.svg";

import Button from "components/Button";
import StaffWorkCard from "components/StaffWorkCard";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authInfo, info } from "store/auth/atom";
import { useStaffDetail } from "api/Profile/Profile";
import BottomPopup from "components/BottomPopup";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Textarea from "components/Textarea";
import { useSendProfileToStaff } from "api/DeliveryProfile/DeliveryProfile";
import { Toast } from "hooks/useToast";
import { useStaffProductions } from "api/Production/Production";
import { useInView } from "react-intersection-observer";
import useScrollRestoration from "hooks/useScrollRestoration";
import ScrollToTop from "components/ScrollToTop";
import ButtonBackground from "components/ButtonBackground";

const StaffDetail = () => {
  const { scrollRef } = useScrollRestoration();
  const navigate = useNavigate();
  const { staffId } = useParams();
  const [sendProfile, setSendProfile] = useState(false);
  const [auth, setAuth] = useRecoilState(authInfo);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const [sendProfileData, setSendProfileData] = useState<{
    staffId: number | undefined;
    profileId: number | null;
    title: string;
    description: string;
  }>({
    staffId: 0,
    profileId: Number(auth?.profileId),
    title: "",
    description: "",
  });
  const [userData, setUserData] = useRecoilState<{
    id: string;
    username: string;
    role: string;
  }>(info);

  const { data, refetch, isLoading } = useStaffDetail(Number(staffId));

  const StaffData = data?.data.data;

  const {
    data: prod,
    fetchNextPage,
    hasNextPage,
  } = useStaffProductions({ memberId: Number(StaffData?.memberId) });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const sortedFilmo = StaffData?.filmographies.sort(
    (a, b) => Number(b.releaseYear) - Number(a.releaseYear)
  );

  const mutate = useSendProfileToStaff();

  const sendProfileToStaff = () => {
    mutate.mutate(
      {
        ...sendProfileData,
        staffId: StaffData?.memberId,
      },
      {
        onSuccess: () => {
          Toast.success("프로필을 보냈어요.");
          setSendProfile(false);
        },
      }
    );
  };

  useEffect(() => {
    refetch();
  }, [refetch, staffId]);

  if (isLoading) return <div></div>;

  return (
    <div className="py-[70px] px-5 pb-24 flex flex-col">
      <ScrollToTop />
      <HeaderWithBackButton title={""}>
        {/* <Bookmark fill="white" stroke="gray" /> */}
      </HeaderWithBackButton>
      {sendProfile && (
        <BottomPopup onClose={() => setSendProfile(false)}>
          <div className="flex flex-col gap-4">
            <div className="BBody20">
              프로필과 함께 보낼 내용을
              <br />
              자유롭게 적어주세요.
            </div>
            <Textarea
              onChange={(e) => {
                setSendProfileData({
                  ...sendProfileData,
                  description: e.target.value,
                });
              }}
              className="h-[112px]"
            />
            <div className="text-Gray05 RBody14">
              <ol className="list-disc list-inside MBody16">
                <li>
                  스탭 회원의 캐스팅 공고와 제안은 캐스팅보트가 책임지지 않으니
                  승인되지 않은 스탭의 허위 공고 또는 제안을 조심하세요.
                </li>
                <li>
                  보내는 프로필에 연기영상/자유영상을 첨부하면 캐스팅 확률이
                  높아져요!
                </li>
                <li>프로필 받은 스탭은 내 연락처를 열람할 수 있어요.</li>
              </ol>
            </div>
            <ButtonBackground>
              <Button
                onClick={() => {
                  sendProfileToStaff();
                }}
                className="bg-Blue04 w-full py-3 MBody16 text-white border-none"
                text={"프로필 보내기"}
              ></Button>
            </ButtonBackground>
          </div>
        </BottomPopup>
      )}
      <div>
        <div className="w-full items-start flex gap-7">
          <img
            src={`${process.env.REACT_APP_MEDIA_URL}${StaffData?.thumbnailUrl}`}
            alt="Thumnai"
            className="w-[160px] h-[196px] rounded-[10px] object-cover"
          />
          <div>
            <div className="flex items-center justify-between BBody20 text-Gray09">
              <div>
                {StaffData?.profileName ? StaffData?.profileName : "홍길동"}
              </div>
            </div>
            <div className="my-2 MCaption12 flex items-start flex-wrap text-Gray05">
              <div>{StaffData?.role.label ? StaffData?.role.label : "Job"}</div>
              <div className="flex items-center">
                <div>•</div>
                <div className="overflow-hidden text-ellipsis break-words max-w-[91px]">
                  {StaffData?.agency}
                </div>
              </div>
            </div>
            <div className="flex flex-col  max-h-[136px] overflow-y-auto gap-2">
              {sortedFilmo?.map((item, i) => {
                return (
                  <div key={i} className="flex items-start gap-2">
                    <div className="RBody14 text-Gray05">
                      {item?.releaseYear}
                    </div>
                    <div className="MBody14 text-Gray09">{item?.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {userData?.role === "ARTIST" && (
          <Button
            onClick={() => {
              if (auth.profileId === null) {
                navigate("/artist/my");
              } else {
                setSendProfile(true);
              }
            }}
            className="w-full bg-Blue04 py-3 text-white !border-none mt-4"
            text={"프로필 보내기"}
          ></Button>
        )}
      </div>
      <div className="w-full my-4">
        {/* <div>
          <Chip title={"전체"}></Chip>
        </div> */}
        {prod?.pages[0].data.content.length !== 0 ? (
          <div className=" gap-x-4 gap-y-2 pt-4 grid grid-cols-2">
            {prod?.pages.map((item: any, i) => {
              return item.data.content.map((prodItem: any) => {
                return (
                  <StaffWorkCard
                    isCasting={prodItem?.isCasting}
                    key={prodItem.productionId}
                    onClick={() =>
                      navigate(`/staff/filmo/${prodItem.productionId}`)
                    }
                    title={prodItem.title}
                    src={prodItem.thumbnailUrl}
                  />
                );
              });
            })}
          </div>
        ) : (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">
              캐스팅 중인 작품이 없어요.
            </div>
          </div>
        )}
        <div ref={ref} />
      </div>
    </div>
  );
};

export default StaffDetail;
