import React, { useEffect, useState } from "react";
import Chip from "components/Chip";
import ContentWrap from "components/ContentWrap";
import Input from "components/Input";
import TitleWrap from "components/TitleWrap";
import { ReactComponent as ProfileInsta } from "assets/profileInsta.svg";
import { ReactComponent as ProfileYoutube } from "assets/profileYoutube.svg";
import Button from "components/Button";
import Textarea from "components/Textarea";
import Dropdown from "components/Dropdown";

import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import { ReactComponent as Delete } from "assets/delete.svg";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import ImgUpload from "components/ImgUpload";
import YouTube from "react-youtube";
import { useMyPage } from "api/Mypage/Mypage";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { Toast } from "hooks/useToast";
import { useUpdateArtistProfileMutation } from "api/Profile/Profile";
import {
  useUploadImagesMutation,
  useUploadVideosMutation,
} from "api/Attachment/Attachment";
import { CASTING_LIST, JOB_LIST, KEYWORD_LIST, TYPE_LIST } from "utils/type";
import { ARTIST_PROFILE } from "type/artist";
import DateInput from "components/DateInput";
import { today } from "utils/onToday";
import ButtonBackground from "components/ButtonBackground";

const UpdateArtistProfile = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [hobbyItem, setHobbyItem] = useState<{
    name: string;
    id?: number;
    isNew: boolean;
  }>({ name: "", isNew: false });
  const [size, setSize] = useState("");
  const [keywordItem, setKeywordItem] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [artistProfile, setArtistProfile] = useState<ARTIST_PROFILE>({
    profileId: 0,
    name: "",
    birthDate: "",
    gender: "",
    job: "",
    sido: "",
    height: undefined,
    weight: undefined,
    education: "",
    rank: "NORMAL",
    agency: "",
    introduce: "",
    instagram: "",
    youtube: "",
    // hashtags: [],
    addHobbies: [],
    hobbies: [],
    removeHobbyIds: [],
    addAttachments: [],
    removeAttachments: [],
    filmographies: [],
    addFilmographies: [],
    removeFilmographyIds: [],
    attachments: [],
    videoLinks: [],
    addVideoLinks: [],
    removeVideoLinks: [],
  });

  const { data, isLoading, isError, refetch } = useMyPage();

  const ArtistData = data?.data.data;

  const { mutate: uploadImages } = useUploadImagesMutation();
  const { mutate: uploadVideos } = useUploadVideosMutation();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const files = e.target.files;
    if (files) {
      if (type === "ADDITIONAL_IMAGE") {
        const imagesCount = artistProfile.attachments!.filter(
          (attachment) => attachment.type === "ADDITIONAL_IMAGE"
        ).length;

        const remainingSlots = 15 - imagesCount;

        if (files.length > remainingSlots) {
          Toast.error("최대 15개 업로드할 수 있어요.");
          return;
        }
      }

      uploadImages(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");

          const uploadedItems = res.data.data.map(
            (item: { attachmentId: number; attachmentUrl: string }) => ({
              id: item.attachmentId,
              isNew: true,
              type: type,
              url: item.attachmentUrl,
            })
          );

          const existingThumbnails = artistProfile.attachments!.filter(
            (attachment) => attachment.type === "THUMBNAIL"
          );

          const existingAdditionalImages = artistProfile.attachments!.filter(
            (attachment) => attachment.type === "ADDITIONAL_IMAGE"
          );

          const newThumbnails = uploadedItems.filter(
            (item) => item.type === "THUMBNAIL"
          );

          const newAdditionalImages = uploadedItems.filter(
            (item) => item.type === "ADDITIONAL_IMAGE"
          );

          const reorderedThumbnails = [
            ...existingThumbnails,
            ...newThumbnails,
          ].map((item) => ({
            ...item,
            orderNumber: 0,
          }));

          const reorderedAdditionalImages = [
            ...existingAdditionalImages,
            ...newAdditionalImages,
          ].map((item, idx) => ({
            ...item,
            orderNumber: idx + 1,
          }));

          const reorderedAttachments = [
            ...reorderedThumbnails,
            ...reorderedAdditionalImages,
          ];

          const reorderedUploadedItems = uploadedItems.map((item) => {
            if (item.type === "THUMBNAIL") {
              return { ...item, orderNumber: 0 };
            }
            const additionalImageIndex =
              reorderedAdditionalImages.findIndex(
                (additionalItem) => additionalItem.id === item.id
              ) + 1;
            return { ...item, orderNumber: additionalImageIndex };
          });

          setArtistProfile({
            ...artistProfile,
            addAttachments: [
              ...artistProfile.addAttachments!,
              ...reorderedUploadedItems,
            ],
            attachments: reorderedAttachments,
          });
        },
      });
    }
  };

  const videoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const additionalVideosCount = artistProfile.attachments!.filter(
        (attachment) => attachment.type === "ADDITIONAL_VIDEO"
      ).length;

      const remainingSlots = 3 - additionalVideosCount;

      if (files.length > remainingSlots) {
        Toast.error("최대 3개까지 업로드 가능해요");
        return;
      }
      uploadVideos(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.data.map(
            (
              item: { attachmentId: number; attachmentUrl: string },
              idx: number
            ) => ({
              id: item.attachmentId,
              isNew: true,
              type: "ADDITIONAL_VIDEO",
              orderNumber: additionalVideosCount + idx + 1,
              url: item.attachmentUrl,
            })
          );
          setArtistProfile({
            ...artistProfile,
            addAttachments: [...artistProfile.addAttachments!, ...updateItem],
            attachments: [...artistProfile.attachments!, ...updateItem],
          });
        },
      });
    }
  };

  const opt = {
    height: "171",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const { mutate: updateMutate, isSuccess: updateSuccess } =
    useUpdateArtistProfileMutation(artistProfile);

  useEffect(() => {
    if (ArtistData) {
      setArtistProfile({
        ...artistProfile,
        profileId: ArtistData.profileId,
        name: ArtistData.profileName,
        birthDate: ArtistData.birthDate,
        gender: ArtistData.gender?.code,
        job: ArtistData.basicInfo.job?.code,
        sido: ArtistData.basicInfo.address?.sido,
        height: ArtistData.basicInfo.height,
        weight: ArtistData.basicInfo.weight,
        education: ArtistData.basicInfo.education,
        rank: ArtistData.rank?.code,
        agency: ArtistData.basicInfo.agency,
        introduce: ArtistData.introduce,
        instagram: ArtistData.snsInfo?.instagram,
        youtube: ArtistData.snsInfo?.youtube,
        // hashtags: ArtistData.hashtags,
        hobbies: ArtistData.hobbies,
        filmographies: ArtistData.filmographies.map(
          (film: {
            id: number;
            title: string;
            type: {
              code: string;
              label: string;
            };
            role: {
              code: string;
              label: string;
            };
            releaseYear: string;
            description: string;
            orderNumber: number;
          }) => ({
            id: film.id,
            title: film.title,
            type: film?.type?.code,
            role: film?.role?.code,
            releaseYear: Number(film.releaseYear),
            description: film.description,
            orderNumber: film.orderNumber,
          })
        ),
        attachments: ArtistData.attachments.map(
          (attachment: {
            id: number;
            type: {
              code: string;
              label: string;
            };
            url: string;
            orderNumber: number;
          }) => ({
            id: attachment.id,
            type: attachment.type.code,
            orderNumber: attachment.orderNumber,
            url: attachment.url,
          })
        ),
        videoLinks: ArtistData.videoLinks.map(
          (video: {
            id: number;
            link: string;
            type: {
              code: string;
              label: string;
            };
            orderNumber: number;
          }) => ({
            id: video.id,
            link: video.link,
            type: video.type.code,
            orderNumber: video.orderNumber,
          })
        ),
      });
    }
  }, [ArtistData]);

  const addFilmographyItem = () => {
    setArtistProfile((prevState) => ({
      ...prevState,
      addFilmographies: [
        {
          isNew: true,
          title: "",
          type: "",
          role: "",
          releaseYear: 0,
          description: "",
          orderNumber: prevState.filmographies!.length,
        },
        ...prevState.addFilmographies!,
      ],
    }));
  };

  const addVideoLink = (newLink: string, type: string) => {
    if (
      newLink.startsWith("https://www.youtube.com") ||
      newLink.startsWith("https://youtu.be/")
    ) {
      setArtistProfile((prevProfile) => {
        const currentVideoCount = prevProfile.videoLinks.length;

        if (currentVideoCount >= 3) {
          Toast.error("최대 3개까지 업로드 가능해요");
          return prevProfile;
        }

        const newOrderNumber = currentVideoCount;
        const newVideoLink = {
          isNew: true,
          link: newLink,
          type: type,
          orderNumber: newOrderNumber,
        };

        return {
          ...prevProfile,
          addVideoLinks: [...prevProfile.addVideoLinks!, newVideoLink],
          videoLinks: [...prevProfile.videoLinks!, newVideoLink],
        };
      });
    } else {
      alert("잘못된 유튜브 링크입니다.");
    }
  };

  const handleDeleteImage = (id: number, isNew?: boolean) => {
    const updatedAttachments = artistProfile.attachments!.filter(
      (item) => item.id !== id
    );
    const updatedAddAttachments = artistProfile.addAttachments!.filter(
      (item) => item.id !== id
    );
    setArtistProfile({
      ...artistProfile,
      attachments: updatedAttachments,
      addAttachments: updatedAddAttachments,
      removeAttachments: isNew
        ? artistProfile.removeAttachments
        : [...(artistProfile.removeAttachments || []), id],
    });
  };

  const handleDeleteVideo = (id: number, isNew?: boolean) => {
    const updatedVideoLinks = artistProfile.videoLinks.filter(
      (item) => item.id !== id
    );
    const updatedAddVideoLinks = artistProfile.addVideoLinks!.filter(
      (item) => item.id !== id
    );

    setArtistProfile({
      ...artistProfile,
      videoLinks: updatedVideoLinks,
      addVideoLinks: updatedAddVideoLinks,
      removeVideoLinks: isNew
        ? artistProfile.removeVideoLinks
        : [...(artistProfile.removeVideoLinks || []), id],
    });
  };

  const removeFilmographyItem = (
    idxToRemove: number,
    id: number,
    isNew?: boolean
  ) => {
    setArtistProfile((pre) => ({
      ...pre,
      addFilmographies: pre.addFilmographies!.filter(
        (_, i) => i !== idxToRemove
      ),
    }));
  };

  const removePreFilmographyItem = (id: number) => {
    const updatedAddAttachments = artistProfile.filmographies!.filter(
      (item) => item.id !== id
    );
    setArtistProfile({
      ...artistProfile,
      filmographies: updatedAddAttachments,
      removeFilmographyIds: [...artistProfile.removeFilmographyIds!, id],
    });
  };
  const submitProfile = () => {
    updateMutate(artistProfile, {
      onSuccess: () => {
        navigate("/artist/my", { replace: true });
        refetch();
      },
    });
  };

  const valid = (data: ARTIST_PROFILE) => {
    const hasThumbnail = data?.attachments!.some(
      (attachment) => attachment.type === "THUMBNAIL"
    );
    if (!hasThumbnail) return "대표 프로필 이미지는 필수입니다. ";
    if (!data.job) return "직업을 선택해주세요.";
    if (!data.name) return "활동명을 입력해주세요.";
    if (data.height === 0) return "키를 입력해주세요.";
    if (data.weight === 0) return "몸무게를 입력해주세요.";
    if (
      artistProfile?.addFilmographies!?.some((filmography) => {
        return (
          !filmography.title ||
          !filmography.type ||
          !filmography.role ||
          filmography.releaseYear! === 0 ||
          !filmography.description
        );
      })
    )
      return "필모그래피는 빈칸없이 입력해주세요";
    return null;
  };

  return (
    <div className="pb-24 px-5 pt-[70px]">
      <HeaderWithBackButton title={`프로필 수정`} />
      <div className="flex items-start gap-10 flex-col">
        <div className="flex-1 flex flex-col gap-10">
          <TitleWrap
            title={"대표 프로필 사진 업로드"}
            subTitle="4:5 / 10MB 이하"
            require
          >
            {artistProfile?.attachments!.some(
              (item) => item.type === "THUMBNAIL"
            ) ? (
              artistProfile
                ?.attachments!.filter((item) => item.type === "THUMBNAIL")
                .map((item, idx) => (
                  <div key={item.id} className="relative">
                    <img
                      className="w-full h-[418px] object-cover rounded-[10px]"
                      src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                      alt="THUMBNAIL"
                    />
                    <XButton
                      onClick={() => {
                        handleDeleteImage(item.id, item.isNew);
                      }}
                      className="absolute cursor-pointer top-4 right-4"
                    />
                  </div>
                ))
            ) : (
              <ImgUpload
                accept="image/jpg,impge/png,image/jpeg"
                onChange={(e) => {
                  imgUpload(e, "THUMBNAIL");
                }}
                id={"profileImg"}
              >
                <div className="w-full MBody16 bg-Blue01 object-cover text-Blue04 px-4 py-3 rounded-[10px] text-center">
                  프로필 사진 업로드
                </div>
              </ImgUpload>
            )}
          </TitleWrap>
          <TitleWrap
            title={"추가사진 업로드"}
            subTitle={`최대 15장 / 보정이 과하거나 노출이 심한 사진은 자제해주세요.`}
          >
            <ImgUpload
              multiple
              accept="image/jpg,impge/png,image/jpeg"
              onChange={(e) => {
                imgUpload(e, "ADDITIONAL_IMAGE");
              }}
              id={"ADDITIONAL_IMAGE"}
            >
              <div className="border border-[#D9D9D9] border-dashed text-center text-Blue04 rounded-[10px] py-4 MBody16">
                <div>추가 사진 업로드</div>
                <IconPlus className="mx-auto mt-4" />
              </div>
            </ImgUpload>
            <div className="flex flex-wrap gap-3 mt-4 items-center">
              {artistProfile
                ?.attachments!.filter(
                  (item) => item.type === "ADDITIONAL_IMAGE"
                )
                .map((item, idx) => (
                  <div key={item.id} className="relative">
                    <img
                      className="w-[110.5px] h-[112px] object-cover rounded-[10px]"
                      src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                      alt="ADDITIONAL_IMAGE"
                    />
                    <XButton
                      onClick={() => {
                        handleDeleteImage(item.id, item.isNew);
                      }}
                      className="absolute cursor-pointer top-4 right-4"
                    />
                  </div>
                ))}
            </div>
          </TitleWrap>
          {/* <TitleWrap
              className="!gap-2"
              title={"최근 3개월 이내 사진 업로드"}
              subTitle={`스튜디오 촬영x`}
            >
              <div>
                <div className="flex items-start flex-wrap gap-2">
                  {artistProfile?.attachments!.some(
                    (item) => item.type === "FULL_BODY"
                  ) ? (
                    artistProfile
                      ?.attachments!.filter((item) => item.type === "FULL_BODY")
                      .map((item, idx) => (
                        <div key={item.id} className="relative">
                          <img
                            className="w-[176px] h-[176px] object-cover"
                            src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                            alt="FULL_BODY"
                          />
                          <XButton
                            onClick={() => {
                              handleDeleteImage(item.id, item.isNew);
                            }}
                            className="absolute cursor-pointer top-4 right-4"
                          />
                        </div>
                      ))
                  ) : (
                    <ImgUpload
                      accept="image/jpg,impge/png,image/jpeg"
                      onChange={(e) => {
                        imgUpload(e, "FULL_BODY");
                      }}
                      id={"FULL_BODY"}
                    >
                      <div className="text-Blue04 cursor-pointer MBody16 rounded-[10px] w-[176px] h-[176px] border border-[#D9D9D9] border-dashed flex flex-col justify-center mx-auto text-center">
                        <div>전신사진</div>
                        <IconPlus className="mx-auto mt-4" />
                      </div>
                    </ImgUpload>
                  )}
                  {artistProfile?.attachments!.some(
                    (item) => item.type === "FRONT_HALF_BODY"
                  ) ? (
                    artistProfile
                      ?.attachments!.filter(
                        (item) => item.type === "FRONT_HALF_BODY"
                      )
                      .map((item, idx) => (
                        <div key={item.id} className="relative">
                          <img
                            key={idx}
                            className="w-[176px] h-[176px] object-cover"
                            src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                            alt="FRONT_HALF_BODY"
                          />
                          <XButton
                            onClick={() => {
                              handleDeleteImage(item.id, item.isNew);
                            }}
                            className="absolute cursor-pointer top-4 right-4"
                          />
                        </div>
                      ))
                  ) : (
                    <ImgUpload
                      accept="image/jpg,impge/png,image/jpeg"
                      onChange={(e) => {
                        imgUpload(e, "FRONT_HALF_BODY");
                      }}
                      id={"FRONT_HALF_BODY"}
                    >
                      <div className="text-Blue04 MBody16 w-[176px] h-[176px] rounded-[10px] border border-[#D9D9D9] border-dashed flex flex-col justify-center mx-auto text-center">
                        <div>정면 상반신 사진</div>
                        <IconPlus className="mx-auto mt-4" />
                      </div>
                    </ImgUpload>
                  )}
                  {artistProfile?.attachments!.some(
                    (item) => item.type === "LEFT_HALF_BODY"
                  ) ? (
                    artistProfile
                      ?.attachments!.filter(
                        (item) => item.type === "LEFT_HALF_BODY"
                      )
                      .map((item, idx) => (
                        <div key={item.id} className="relative">
                          <img
                            key={idx}
                            className="w-[176px] h-[176px] object-cover"
                            src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                            alt="LEFT_HALF_BODY"
                          />
                          <XButton
                            onClick={() => {
                              handleDeleteImage(item.id, item.isNew);
                            }}
                            className="absolute cursor-pointer top-4 right-4"
                          />
                        </div>
                      ))
                  ) : (
                    <ImgUpload
                      accept="image/jpg,impge/png,image/jpeg"
                      onChange={(e) => {
                        imgUpload(e, "LEFT_HALF_BODY");
                      }}
                      id={"LEFT_HALF_BODY"}
                    >
                      <div className="text-Blue04 MBody16 border border-[#D9D9D9] rounded-[10px] border-dashed w-[176px] h-[176px] flex flex-col justify-center mx-auto text-center">
                        <div>
                          좌측 상반신 사진
                          <br />
                          (45도 각도)
                        </div>
                        <IconPlus className="mx-auto mt-4" />
                      </div>
                    </ImgUpload>
                  )}
                  {artistProfile?.attachments!.some(
                    (item) => item.type === "RIGHT_HALF_BODY"
                  ) ? (
                    artistProfile
                      ?.attachments!.filter(
                        (item) => item.type === "RIGHT_HALF_BODY"
                      )
                      .map((item, idx) => (
                        <div key={item.id} className="relative">
                          <img
                            className="w-[176px] h-[176px] object-cover"
                            src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                            alt="RIGHT_HALF_BODY"
                          />
                          <XButton
                            onClick={() => {
                              handleDeleteImage(item.id, item.isNew);
                            }}
                            className="absolute cursor-pointer top-4 right-4"
                          />
                        </div>
                      ))
                  ) : (
                    <ImgUpload
                      accept="image/jpg,impge/png,image/jpeg"
                      onChange={(e) => {
                        imgUpload(e, "RIGHT_HALF_BODY");
                      }}
                      id={"RIGHT_HALF_BODY"}
                    >
                      <div className="text-Blue04 MBody16 border border-[#D9D9D9] rounded-[10px] border-dashed w-[176px] h-[176px] flex flex-col justify-center mx-auto text-center">
                        <div>
                          우측 상반신 사진
                          <br />
                          (45도 각도)
                        </div>
                        <IconPlus className="mx-auto mt-4" />
                      </div>
                    </ImgUpload>
                  )}
                </div>
              </div>
            </TitleWrap> */}
          <TitleWrap
            title={"영상 업로드"}
            subTitle="연기영상/자유영상을 첨부하면 캐스팅 확률이 높아져요!"
          >
            <TitleWrap
              title={"영상 파일 첨부"}
              className="mb-4"
              subTitle="50mb/최대 3개"
            >
              <ImgUpload
                accept=".avi, .mov, .mp4"
                onChange={(e) => {
                  videoUpload(e);
                }}
                id={"ProfileVideo"}
              >
                <div className="w-full MBody16 bg-Blue01 text-Blue04 px-4 py-3 rounded-[10px] text-center">
                  영상 파일 업로드
                </div>
              </ImgUpload>
            </TitleWrap>
            {artistProfile?.attachments!.some(
              (item) => item.type === "ADDITIONAL_VIDEO"
            )
              ? artistProfile
                  ?.attachments!.filter(
                    (item) => item.type === "ADDITIONAL_VIDEO"
                  )
                  .map((item, idx) => (
                    <div key={item.id} className="relative mb-2">
                      <video
                        controls
                        className="w-full h-[186px] rounded-[10px]"
                      >
                        <source
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                        ></source>
                      </video>
                      <XButton
                        onClick={() => {
                          handleDeleteImage(item.id, item.isNew);
                        }}
                        className="absolute cursor-pointer top-4 right-4"
                      />
                    </div>
                  ))
              : ""}
            <TitleWrap title={"유튜브 링크"}>
              <div className="flex items-center gap-2 w-full">
                <Input
                  value={youtubeLink}
                  onChange={(e) => {
                    setYoutubeLink(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (youtubeLink !== "") {
                      if (e.key === "Enter") {
                        addVideoLink(youtubeLink, "YOUTUBE");
                        setYoutubeLink("");
                      }
                    }
                  }}
                  className="!w-[292px]"
                  placeholder="유튜브 링크를 추가해 주세요"
                  type={"text"}
                ></Input>
                <Button
                  onClick={() => {
                    if (youtubeLink !== "") {
                      addVideoLink(youtubeLink, "YOUTUBE");
                      setYoutubeLink("");
                    }
                  }}
                  className="w-[60px] text-Blue04 bg-Blue01 !MBody16 border-none"
                  text="추가"
                ></Button>
              </div>
            </TitleWrap>
            {/* <div className="MBody14 w-full text-Gray09 my-4">
                💡드래그앤드롭으로 영상 순서를 바꿀 수 있어요
              </div> */}
            <div
              className={`flex flex-col w-full gap-2 ${
                artistProfile.videoLinks.length !== 0 && "mt-4"
              }`}
            >
              {artistProfile.videoLinks.map((item, idx) => {
                let link = "";

                if (item.link.startsWith("https://www.youtube.com")) {
                  link = item.link.split("watch?v=")[1];
                } else {
                  link = item.link.split("?")[0].split("/")[3];
                }
                return (
                  <div key={item.orderNumber} className="relative group">
                    <YouTube
                      className={"w-full"}
                      key={item.orderNumber}
                      opts={opt}
                      iframeClassName="youtubeRounded"
                      videoId={link}
                    />
                    <XButton
                      onClick={() => {
                        handleDeleteVideo(item.id!);
                      }}
                      className="absolute hidden cursor-pointer top-4 right-4 w-[38px] h-[38px] bg-black/80 group-hover:block"
                    />
                  </div>
                );
              })}
            </div>
          </TitleWrap>
        </div>
        <div className="flex-1 flex flex-col gap-10 ">
          <TitleWrap title={"직업"} require>
            <div className="flex items-center flex-wrap gap-2">
              {JOB_LIST.map((item) => {
                return (
                  <Chip
                    selected={artistProfile.job === item.role}
                    onClick={() => {
                      if (artistProfile.job === item.role) {
                        setArtistProfile({ ...artistProfile, job: "" });
                      } else {
                        setArtistProfile({
                          ...artistProfile,
                          job: item.role,
                        });
                      }
                    }}
                    key={item.id}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap>
          <TitleWrap title={"활동명"} require>
            <Input
              value={artistProfile?.name}
              onChange={(e) => {
                setArtistProfile({ ...artistProfile, name: e.target.value });
              }}
              className="w-[589px]"
              placeholder="활동명을 입력해 주세요"
              type={"text"}
            ></Input>
          </TitleWrap>
          <TitleWrap title={"생년월일"}>
            <DateInput
              value={artistProfile?.birthDate}
              onChange={(e) => {
                setArtistProfile({
                  ...artistProfile,
                  birthDate: e.target.value,
                });
              }}
              min="1920-01-01"
              max={today()}
              className="w-[589px]"
              placeholder="프로필 생년월일"
            ></DateInput>
          </TitleWrap>
          <TitleWrap title={"성별"}>
            <div className="flex items-center gap-4 w-full">
              <Button
                onClick={() => {
                  setArtistProfile({ ...artistProfile, gender: "FEMALE" });
                }}
                className={`
                           w-full border ${
                             artistProfile?.gender === "FEMALE"
                               ? "border-Blue04 text-Blue04 bg-Gray00"
                               : "border-Gray03 bg-Gray00 text-Gray05"
                           } cursor-default
                           `}
                text={"여성"}
              />
              <Button
                onClick={() => {
                  setArtistProfile({ ...artistProfile, gender: "MALE" });
                }}
                className={`
                    w-full border RBody16 cursor-default
                     ${
                       artistProfile?.gender === "MALE"
                         ? "border-Blue04 text-Blue04 bg-Gray00"
                         : "border-Gray03 bg-Gray00 text-Gray05"
                     }`}
                text={"남성"}
              />
            </div>
          </TitleWrap>
          <TitleWrap title={"SNS"}>
            <Input
              value={artistProfile?.instagram}
              onChange={(e) => {
                setArtistProfile({
                  ...artistProfile,
                  instagram: e.target.value,
                });
              }}
              imagePosition="L"
              className="w-[589px] h-12 mb-4"
              placeholder="인스타그램 ID"
              type={"text"}
            >
              <ProfileInsta className="left-2.5 top-2.5 absolute" />
            </Input>
            <Input
              value={artistProfile?.youtube}
              onChange={(e) => {
                setArtistProfile({
                  ...artistProfile,
                  youtube: e.target.value,
                });
              }}
              imagePosition="L"
              className="w-[589px] h-12"
              placeholder="유튜브 채널 URL (영상링크 x /채널 링크를 올려 주세요)"
              type={"text"}
            >
              <ProfileYoutube className="left-2.5 top-2.5 absolute" />
            </Input>
          </TitleWrap>
          {/* <TitleWrap title={"키워드"}>
              <div className="flex items-center flex-wrap gap-2">
                {KEYWORD_LIST?.map((item) => {
                  const isSelected = artistProfile?.hashtags.some(
                    (hashtag: { name: string; id: number }) =>
                      hashtag.id === item.id
                  )
                  return (
                    <Chip
                      selected={isSelected}
                      onClick={() => {
                        if (isSelected) {
                          setArtistProfile({
                            ...artistProfile,
                            hashtags: artistProfile.hashtags.filter(
                              (hashtag: { name: string; id: number }) =>
                                hashtag.id !== item.id
                            ),
                          });
                        } else {
                          setArtistProfile({
                            ...artistProfile,
                            hashtags: [...artistProfile.hashtags, item],
                          });
                        }
                      }}
                      key={item.id}
                      title={item.title}
                    />
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title={"직접입력"} subTitle="최대 10자">
              <div className="flex items-center gap-2">
                <Input
                  value={keywordItem}
                  onChange={(e) => {
                    setKeywordItem(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (keywordItem !== "") {
                      if (e.key === "Enter") {
                        setArtistProfile({
                          ...artistProfile,
                          hashtags: [
                            ...artistProfile.hashtags,
                            keywordItem.trim(),
                          ],
                        });
                        setKeywordItem("");
                      }
                    }
                  }}
                  className="!w-[292px]"
                  placeholder="키워드를 추가해 주세요"
                  type={"text"}
                ></Input>
                <Button
                  onClick={() => {
                    if (keywordItem !== "") {
                      setArtistProfile({
                        ...artistProfile,
                        hashtags: [...artistProfile.hashtags, keywordItem],
                      });
                      setKeywordItem("");
                    }
                  }}
                  className="w-[60px] text-Blue04 bg-Blue01 !MBody16 border-none"
                  text="추가"
                ></Button>
              </div>
              <div className="flex items-center flex-wrap mt-4 gap-2">
                {artistProfile.hashtags
                  .filter((hashtags) => !keyword.includes(hashtags))
                  .map((hashtag, index) => (
                    <Chip
                      closeButton
                      selected
                      key={index}
                      onClick={() => {
                        setArtistProfile({
                          ...artistProfile,
                          hashtags: artistProfile.hashtags.filter(
                            (x) => x !== hashtag
                          ),
                        });
                      }}
                      title={hashtag}
                    ></Chip>
                  ))}
              </div>
            </TitleWrap> */}
          <hr className="h-px w-full text-Gray03" />
          <div className="flex items-center gap-5">
            <TitleWrap title={"키"} require>
              <Input
                value={artistProfile?.height}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    height: Number(e.target.value.replace(/[^0-9]/g, "")),
                  });
                }}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                className="w-[285px]"
                inputMode="numeric"
                placeholder="키를 입력해 주세요 (cm)"
                type={"number"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="몸무게" require>
              <Input
                value={artistProfile?.weight}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    weight: Number(e.target.value.replace(/[^0-9]/g, "")),
                  });
                }}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                className="w-[285px]"
                placeholder="몸무게를 입력해 주세요 (kg)"
                type={"number"}
                inputMode="numeric"
              ></Input>
            </TitleWrap>
          </div>
          <TitleWrap title="학력">
            <Input
              value={artistProfile?.education}
              onChange={(e) => {
                setArtistProfile({
                  ...artistProfile,
                  education: e.target.value,
                });
              }}
              className="w-[589px] mb-4"
              placeholder="최종학력을 입력해 주세요"
              type={"text"}
            ></Input>
            {/* <div className="flex items-center">
                  <Checkbox id="학력" title={"비공개"}></Checkbox>
                  <div className="group relative">
                    <Tooltip />
                    <div className="absolute group-hover:block hidden RBody14 rounded-[10px] p-2 border box-content border-Gray03 text-Gray09 top-6 bg-white left-0 w-[308px]">
                      아티스트 유저에게는 가려지고 스탭 유저에게만 공개돼요
                    </div>
                  </div>
                </div> */}
          </TitleWrap>
          <TitleWrap title="매니저/소속사">
            <Input
              value={artistProfile?.agency}
              onChange={(e) => {
                setArtistProfile({
                  ...artistProfile,
                  agency: e.target.value,
                });
              }}
              className="w-[589px]"
              placeholder="매니저/소속사를 입력해 주세요"
              type={"text"}
            ></Input>
          </TitleWrap>
          <TitleWrap title="특기">
            <div>
              <div className="flex items-center gap-2">
                <Input
                  value={hobbyItem.name}
                  onChange={(e) => {
                    setHobbyItem({ name: e.target.value, isNew: true });
                  }}
                  onKeyDown={(e) => {
                    if (hobbyItem.name) {
                      if (e.key === "Enter") {
                        setArtistProfile({
                          ...artistProfile,
                          hobbies: [...artistProfile.hobbies!, hobbyItem],
                          addHobbies: [
                            ...artistProfile?.addHobbies!,
                            hobbyItem?.name!,
                          ],
                        });
                        setHobbyItem({ name: "", isNew: false });
                      }
                    }
                  }}
                  className="!w-[292px]"
                  placeholder="특기를 추가해 주세요"
                  type={"text"}
                ></Input>
                <Button
                  onClick={() => {
                    if (hobbyItem?.name !== "") {
                      setArtistProfile({
                        ...artistProfile,
                        hobbies: [...artistProfile.hobbies!, hobbyItem],
                        addHobbies: [
                          ...artistProfile?.addHobbies,
                          hobbyItem.name,
                        ],
                      });
                      setHobbyItem({ name: "", isNew: false });
                    }
                  }}
                  className="w-[60px] text-Blue04 bg-Blue01 !MBody16 border-none"
                  text="추가"
                ></Button>
              </div>
              <div className="flex items-center flex-wrap mt-4 gap-2">
                {artistProfile?.hobbies!.map(
                  (
                    item: { name: string; id: number; isNew?: boolean },
                    idx: React.Key
                  ) => {
                    return (
                      <Chip
                        closeButton
                        key={idx}
                        selected
                        onClick={() => {
                          const updatedHobbied = artistProfile.hobbies!.filter(
                            (h: { id: number }) => item.id !== h.id
                          );
                          const updatedAddHobbied =
                            artistProfile.addHobbies!.filter(
                              (addH: {
                                id?: number;
                                isNew?: boolean;
                                name?: string;
                              }) => item.id !== addH.id
                            );
                          setArtistProfile({
                            ...artistProfile,
                            hobbies: updatedHobbied,
                            addHobbies: updatedAddHobbied,
                            removeHobbyIds: item?.isNew
                              ? artistProfile.removeHobbyIds
                              : [
                                  ...(artistProfile.removeHobbyIds || []),
                                  item.id,
                                ],
                          });
                        }}
                        title={item.name}
                      ></Chip>
                    );
                  }
                )}
              </div>
              <div
                className={`text-Gray05 RBody14 ${
                  artistProfile?.hobbies.length !== 0 && "mt-4"
                }`}
              >
                예시) 영어 / 중국어 / 스페인어 / 불어 / 러시아어 / 일본어 /
                경상도 사투리 / 제주도 사투리 / 승마 / 태권도 / 수영 / 주짓수 /
                호신술 / 운전 / 발레 / 현대무용 / 한국무용 / 피아노 등
              </div>
            </div>
          </TitleWrap>
          <TitleWrap title="거주지" subTitle="시까지 입력">
            <Input
              value={artistProfile?.sido}
              onChange={(e) => {
                setArtistProfile({
                  ...artistProfile,
                  sido: e.target.value,
                });
              }}
              className="w-[589px]"
              placeholder="거주지를 입력해 주세요(시까지 입력)"
              type={"text"}
            ></Input>
          </TitleWrap>
          <TitleWrap title="자기소개" subTitle="최대 2000자">
            <Textarea
              value={artistProfile?.introduce}
              onChange={(e) => {
                setArtistProfile({
                  ...artistProfile,
                  introduce: e.target.value,
                });
              }}
              placeholder="개인정보(휴대폰번호, 주소 등) 작성을 유의해주세요."
              className="w-full h-[270px]"
            />
          </TitleWrap>
          <hr className="h-px w-full text-Gray03" />
          <div>
            <div className="flex mb-4 justify-between items-center">
              <div className="BBody20 text-Gray09">필모그래피</div>
              <IconPlus
                onClick={() => {
                  addFilmographyItem();
                }}
                className="cursor-pointer"
              />
            </div>
            {artistProfile?.addFilmographies!.map((item, idx) => {
              return (
                <div key={idx} className="flex flex-col gap-4">
                  <div
                    onClick={() => {
                      removeFilmographyItem(idx, item?.id!, item?.isNew!);
                    }}
                    className={`${
                      idx === 0 ? "mt-0" : "mt-4"
                    }  flex items-center gap-2 cursor-pointer`}
                  >
                    <hr className="border-t border-dashed bg-Gray03 flex-1" />
                    <Delete />
                    <hr className="border-t border-dashed bg-Gray03 flex-1" />
                  </div>

                  <div className={`flex items-center gap-5`}>
                    <TitleWrap title={"제작년도"} subTitle="0000로 적어주세요">
                      <Input
                        value={
                          artistProfile?.addFilmographies![idx]?.releaseYear
                        }
                        onChange={(e) => {
                          const updatedFilmographies = [
                            ...artistProfile.addFilmographies!,
                          ];
                          updatedFilmographies[idx].releaseYear = Number(
                            e.target.value.replace(/[^0-9]/g, "").slice(0, 4)
                          );
                          setArtistProfile({
                            ...artistProfile,
                            addFilmographies: updatedFilmographies,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (["e", "E", "+", "-", "."].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        placeholder=""
                        type={"number"}
                        inputMode="numeric"
                      ></Input>
                    </TitleWrap>
                    <TitleWrap title="분류">
                      <Dropdown
                        innerText={
                          artistProfile.addFilmographies![idx]?.type === "MOVIE"
                            ? "영화"
                            : artistProfile.addFilmographies![idx]?.type ===
                              "TV_SERIES"
                            ? "드라마"
                            : artistProfile.addFilmographies![idx]?.type ===
                              "VARIETY_SHOW"
                            ? "예능"
                            : artistProfile.addFilmographies![idx]?.type ===
                              "MUSIC_VIDEO"
                            ? "뮤직비디오"
                            : artistProfile.addFilmographies![idx]?.type ===
                              "WEB_SERIES"
                            ? "웹드라마"
                            : artistProfile.addFilmographies![idx]?.type ===
                              "SHORT_FORM"
                            ? "숏폼"
                            : artistProfile.addFilmographies![idx]?.type ===
                              "INDEPENDENT_FILM"
                            ? "독립영화"
                            : artistProfile.addFilmographies![idx]?.type ===
                              "SHORT_FILM"
                            ? "단편영화"
                            : artistProfile.addFilmographies![idx]?.type ===
                              "THEATER"
                            ? "연극"
                            : artistProfile.addFilmographies![idx]?.type ===
                              "ADVERTISEMENT"
                            ? "광고"
                            : artistProfile.addFilmographies![idx]?.type ===
                              "MUSICAL"
                            ? "뮤지컬"
                            : artistProfile.addFilmographies![idx]?.type ===
                              "DOCUMENTARY"
                            ? "다큐멘터리"
                            : "선택"
                        }
                        width="w-[170px]"
                      >
                        <div>
                          {TYPE_LIST.map((item) => {
                            return (
                              <li
                                onClick={() => {
                                  const updatedFilmographies = [
                                    ...artistProfile.addFilmographies!,
                                  ];
                                  updatedFilmographies[idx].type = item.role;
                                  setArtistProfile({
                                    ...artistProfile,
                                    addFilmographies: updatedFilmographies,
                                  });
                                }}
                                key={item.id}
                              >
                                {item.title}
                              </li>
                            );
                          })}
                        </div>
                      </Dropdown>
                    </TitleWrap>
                  </div>
                  <TitleWrap title="작품 제목" subTitle="최대 36자">
                    <Input
                      value={artistProfile?.addFilmographies![idx]?.title}
                      onChange={(e) => {
                        const updatedFilmographies = [
                          ...artistProfile.addFilmographies!,
                        ];
                        updatedFilmographies[idx].title = e.target.value;
                        setArtistProfile({
                          ...artistProfile,
                          addFilmographies: updatedFilmographies,
                        });
                      }}
                      className="w-[589px]"
                      placeholder="작품 제목을 입력해 주세요"
                      type={"text"}
                    ></Input>
                  </TitleWrap>
                  <div className="flex items-center gap-5">
                    <TitleWrap title="배역">
                      <Dropdown
                        innerText={
                          artistProfile.addFilmographies![idx]?.role ===
                          "LEADING_ACTOR"
                            ? "주연"
                            : artistProfile.addFilmographies![idx]?.role ===
                              "SUPPORTING_ACTOR"
                            ? "조연"
                            : artistProfile.addFilmographies![idx]?.role ===
                              "BIT_PART"
                            ? "단역"
                            : artistProfile.addFilmographies![idx]?.role ===
                              "IMAGE_BIT_PART"
                            ? "이미지"
                            : artistProfile.addFilmographies![idx]?.role ===
                              "EXTRA"
                            ? "보조출연"
                            : artistProfile.addFilmographies![idx]?.role ===
                              "CAMEO"
                            ? "특별출연"
                            : "선택"
                        }
                        width="w-[170px]"
                      >
                        <div>
                          {CASTING_LIST.map((item) => {
                            return (
                              <li
                                onClick={() => {
                                  const updatedFilmographies = [
                                    ...artistProfile.addFilmographies!,
                                  ];
                                  updatedFilmographies[idx].role = item.role;
                                  setArtistProfile({
                                    ...artistProfile,
                                    addFilmographies: updatedFilmographies,
                                  });
                                }}
                                key={item.id}
                              >
                                {item.title}
                              </li>
                            );
                          })}
                        </div>
                      </Dropdown>
                    </TitleWrap>
                    <TitleWrap title={"배역명"}>
                      <Input
                        value={
                          artistProfile?.addFilmographies![idx]?.description
                        }
                        onChange={(e) => {
                          const updatedFilmographies = [
                            ...artistProfile.addFilmographies!,
                          ];
                          updatedFilmographies[idx].description =
                            e.target.value;
                          setArtistProfile({
                            ...artistProfile,
                            addFilmographies: updatedFilmographies,
                          });
                        }}
                        className="w-[285px]"
                        placeholder="배역명을 입력해 주세요"
                        type={"text"}
                      ></Input>
                    </TitleWrap>
                  </div>
                </div>
              );
            })}
            {artistProfile?.filmographies!.map((item, idx) => {
              return (
                <div key={idx} className="flex flex-col gap-4">
                  <div
                    onClick={() => {
                      removePreFilmographyItem(item?.id!);
                    }}
                    className={`${
                      artistProfile.addFilmographies?.length !== 0 || idx !== 0
                        ? "mt-4"
                        : "mt-0"
                    }  flex items-center gap-2 cursor-pointer`}
                  >
                    <hr className="border-t border-dashed bg-Gray03 flex-1" />
                    <Delete />
                    <hr className="border-t border-dashed bg-Gray03 flex-1" />
                  </div>

                  <div className={`flex items-center gap-5`}>
                    <TitleWrap title={"제작연도"} subTitle="0000로 적어주세요">
                      <Input
                        disabled
                        value={String(item?.releaseYear)}
                        type={"text"}
                      />
                    </TitleWrap>
                    <TitleWrap title="분류">
                      <Input
                        disabled
                        value={
                          item.type === "MOVIE"
                            ? "영화"
                            : item?.type === "TV_SERIES"
                            ? "드라마"
                            : item.type === "VARIETY_SHOW"
                            ? "예능"
                            : item.type === "MUSIC_VIDEO"
                            ? "뮤직비디오"
                            : item.type === "WEB_SERIES"
                            ? "웹드라마"
                            : item.type === "ADVERTISEMENT"
                            ? "광고"
                            : item.type === "SHORT_FORM"
                            ? "숏폼"
                            : item.type === "MUSICAL"
                            ? "뮤지컬"
                            : item.type === "INDEPENDENT_FILM"
                            ? "독립영화"
                            : item.type === "SHORT_FILM"
                            ? "단편영화"
                            : item.type === "THEATER"
                            ? "연극"
                            : item.type === "DOCUMENTARY"
                            ? "다큐멘터리"
                            : ""
                        }
                        type={"text"}
                      />
                    </TitleWrap>
                  </div>
                  <TitleWrap title="작품 제목" subTitle="최대 36자">
                    <Input value={item?.title} disabled type={"text"} />
                  </TitleWrap>
                  <div className="flex items-center gap-5">
                    <TitleWrap title="배역">
                      <Input
                        value={
                          item?.role === "LEADING_ACTOR"
                            ? "주연"
                            : item?.role === "SUPPORTING_ACTOR"
                            ? "조연"
                            : item?.role === "BIT_PART"
                            ? "단역"
                            : item?.role === "IMAGE_BIT_PART"
                            ? "이미지"
                            : item?.role === "EXTRA"
                            ? "보조출연"
                            : item?.role === "CAMEO"
                            ? "특별출연"
                            : ""
                        }
                        disabled
                        type={"text"}
                      />
                    </TitleWrap>
                    <TitleWrap title={"배역명"}>
                      <Input value={item?.description} disabled type={"text"} />
                    </TitleWrap>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ButtonBackground>
        <Button
          onClick={() => {
            const validError = valid(artistProfile);
            if (validError !== null) {
              Toast.error(validError);
            } else {
              submitProfile();
            }
          }}
          className="w-full h-12 bg-Blue04 text-white border-none"
          text={`수정하기`}
        ></Button>
      </ButtonBackground>
    </div>
  );
};

export default UpdateArtistProfile;
