import React, { useCallback, useEffect, useState } from "react";

import {
  useDeliveryProfileDetail,
  useSendProfiles,
} from "api/DeliveryProfile/DeliveryProfile";
import BottomPopup from "components/BottomPopup";
import Button from "components/Button";

import { ReactComponent as BlankStar } from "assets/blankStar.svg";
import { ReactComponent as Star } from "assets/star.svg";
import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import useScrollRestoration from "hooks/useScrollRestoration";
import ButtonBackground from "components/ButtonBackground";

const ArtistSendProfile = () => {
  const navigate = useNavigate();
  const { scrollRef } = useScrollRestoration();
  const [info, setInfo] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [feedbackInfo, setFeedbackInfo] = useState({
    staffName: "",
    rating: 0,
    comment: "",
  });
  const [deliveryId, setDeliveryId] = useState(0);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch: artistsRefetch,
  } = useSendProfiles({
    status: "",
  });
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const { data: deliveryData } = useDeliveryProfileDetail(Number(deliveryId));
  const detail = deliveryData?.data.data;

  const desc = [
    { id: 0, title: "분야", desc: detail?.productionFormat?.label },
    { id: 1, title: "제작사", desc: detail?.companyName },
    { id: 2, title: "감독", desc: detail?.directorName },
    { id: 3, title: "배역", desc: detail?.shootingDetails?.role?.label },
    { id: 4, title: "성별", desc: detail?.shootingDetails?.gender?.label },
    {
      id: 5,
      title: "나이",
      desc: `${detail?.shootingDetails?.minAge} ~ ${detail?.shootingDetails?.maxAge}`,
    },
    { id: 6, title: "출연료", desc: detail?.shootingDetails?.fee },
    { id: 7, title: "마감일자", desc: "" },
    { id: 8, title: "캐스팅", desc: "" },
  ];

  const proc = [
    { id: 0, title: "보낸 날짜", desc: detail?.createdAt.split("T")[0] },
    // { id: 1, title: "진행상태", desc: detail?.status?.label },
    { id: 1, title: "캐스팅상태", desc: detail?.castingStatus?.label },
  ];

  const ratingStartValue = () => {
    let result = [];
    for (let i = 0; i < 5; i++) {
      result.push(
        <div key={i + 1}>
          {i + 1 <= feedbackInfo.rating ? <Star /> : <BlankStar />}
        </div>
      );
    }
    return result;
  };

  return (
    <div className="py-[70px] px-5 pb-24">
      {feedback && (
        <BottomPopup
          onClose={() => {
            setFeedback(false);
            setFeedbackInfo({
              staffName: "",
              rating: 0,
              comment: "",
            });
          }}
        >
          <div className="BBody20">
            {feedbackInfo?.staffName}님의 프로필 피드백
          </div>
          <div className="mt-4 mb-10">
            <div className="flex items-center gap-1">
              <div className="RBody16 text-Gray09">별점</div>
              <div className="BBody16 text-Blue04">
                {feedbackInfo?.rating ? feedbackInfo?.rating : 0}/5
              </div>
            </div>
            <div className="flex items-center mt-4 gap-4">
              {ratingStartValue()}
            </div>
          </div>
          <div className="p-4 bg-Gray01 rounded-[10px] RBody16">
            {feedbackInfo?.comment}
          </div>
          <ButtonBackground>
            <Button
              onClick={() => {
                setFeedback(false);
              }}
              text={"닫기"}
              className="w-full text-Gray05 flex-1 bg-Gray03 "
            />
          </ButtonBackground>
        </BottomPopup>
      )}
      {info && (
        <BottomPopup
          onClose={() => {
            setInfo(false);
          }}
        >
          <div className="flex items-center gap-2">
            <div className="BBody18 max-w-[305px] whitespace-nowrap text-ellipsis">
              {detail?.castingCallTitle
                ? detail?.castingCallTitle
                : detail?.directorName}
            </div>
            {detail?.isOffered && (
              <div className="text-Blue04 bg-Blue01 MCaption10 px-1 py-0.5 rounded-md">
                제안
              </div>
            )}
          </div>
          <div className="flex flex-col bg-Gray01 rounded-[10px] p-4 mt-4">
            <div className="flex flex-col gap-1">
              {desc.map((descItem) => {
                if (descItem.desc && descItem.desc !== "undefined ~ undefined")
                  return (
                    <div
                      key={descItem.id}
                      className="flex items-start gap-4 MBody14 last:pb-2 last:mb-2 last:border-b last:border-Gray03"
                    >
                      <div className="w-[107px] text-Gray05">
                        {descItem.title}
                      </div>
                      <div className="flex-1 text-Gray09">{descItem.desc}</div>
                    </div>
                  );
              })}
            </div>
            <div className="flex flex-col gap-1">
              {proc.map((descItem) => {
                if (descItem.desc)
                  return (
                    <div
                      key={descItem.id}
                      className="flex items-start gap-4 MBody16"
                    >
                      <div className="w-[107px] text-Gray05">
                        {descItem.title}
                      </div>
                      <div
                        className={`flex-1  ${
                          descItem.title === "진행상태"
                            ? "text-Blue04"
                            : "text-Gray09"
                        }`}
                      >
                        {descItem.desc}
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>
          <ButtonBackground>
            {detail?.status.code === "FEEDBACK" ? (
              <Button
                onClick={() => {
                  setInfo(false);
                  setFeedback(true);
                  setFeedbackInfo({
                    staffName: detail?.staffName,
                    rating: detail.rating,
                    comment: detail.comments,
                  });
                }}
                className="text-Blue04 MBody16 mt-4 w-full bg-Gray00 border border-Blue04"
                text={"프로필 피드백 확인하기"}
              />
            ) : (
              <Button
                onClick={() => {
                  setInfo(false);
                }}
                text={"닫기"}
                className="w-full text-Gray05 flex-1 bg-Gray03 "
              />
            )}
          </ButtonBackground>
        </BottomPopup>
      )}
      <HeaderWithBackButton title="보낸 프로필" />
      {data?.pages[0].data.content.length === 0 ? (
        <div className="mt-36 items-center text-center">
          <div className="text-Gray05 RBody14">보낸 프로필이 없어요.</div>
        </div>
      ) : (
        <div>
          {data?.pages.map((item: any) =>
            item.data.content.map((sendItem: any) => (
              <div
                key={sendItem?.deliveryProfileId}
                onClick={() => {
                  setDeliveryId(sendItem.deliveryProfileId);
                  setInfo(true);
                }}
                className={`first:pt-0 py-4 border-b cursor-pointer border-Gray03`}
              >
                <div className="RBody14 text-Gray05 mb-2">
                  {sendItem?.createdAt.split("T")[0]}
                </div>
                <div className="flex items-center gap-1.5">
                  {sendItem?.isOffered && (
                    <div className="text-Blue04 bg-Blue01 MCaption10 px-1 py-0.5 rounded-md">
                      제안
                    </div>
                  )}
                  <div
                    className={`MBody16 ${
                      sendItem.status.code === "FEEDBACK"
                        ? "text-Blue04"
                        : "text-Gray09"
                    }`}
                  >
                    {sendItem?.castingCallTitle || sendItem.staffName}
                  </div>
                </div>
              </div>
            ))
          )}
          <div ref={ref} />
        </div>
      )}
    </div>
  );
};

export default ArtistSendProfile;
